import video from '../../assets/aqso_vid.mp4';
import '../../Styles/HomePage.css';
import Footer from '../Footer';
import ProductCard from '../LeoFarataFrameWorks/ProductCard';
import SupplierCard from '../LeoFarataFrameWorks/SupplierCard';
import SearchForm from '../LeoFarataFrameWorks/SearchForm';
import HomeCarousel from '../LeoFarataFrameWorks/HomeCarousel';
import LFWarningAlert from '../LeoFarataFrameWorks/LFWarningAlert';
import ProductCardSkeleton from '../LeoFarataFrameWorks/ProductCardSkeleton';
import SupplierCardSkeleton from '../LeoFarataFrameWorks/SupplierCardSkeleton';
import CategoryCard from '../LeoFarataFrameWorks/CategoryCard';
import LFContactSupplierDialogForm from '../LeoFarataFrameWorks/LFContactSupplierDialogForm';
import { ProductDeletedSnackbarContext } from '../Contexts';
//import ProductsData from '../JSON/ProductsData';
//import SuppliersData from '../JSON/SuppliersData';
import Banner from '../Banner';
import {useEffect, useState, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth, db, getDocs, collection, query, where, limit, deleteDoc, deleteObject, ref, storage, doc, orderBy} from '../Firebase/firebase';
import Fab from '@mui/material/Fab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

/*// Recupération des 4 produits de la base de données
const productsDocs =  await getDocs(query(collection(db, "products"), limit(4), orderBy("createdAt", "desc")))
let productsList = [];
productsDocs.forEach(doc => productsList.push(doc.data()));

// Recupération des 4 derniers utilisateurs de la base de données
const usersDocs = await getDocs(query(collection(db, "users"), where("accounttype", "==", "Fournisseur"), limit(4), orderBy("createdAt", "desc")))
let users = [];
usersDocs.forEach(doc => users.push(doc.data()));*/


export default function HomeView(){

  const productsCategories = [
    {
      title: "Matières premières",
      image: "/public_assets/Categories/categorie_matières_premieres.png",
      headerParamName: ""
    },

    {
      title: "Produits dérivés",
      image: "/public_assets/Categories/categorie_produits_derivées.png",
      headerParamName: ""
    },

    {
      title: "Autres",
      image: "/public_assets/Categories/categorie_autre.png",
      headerParamName: ""
    }
  ];

  let userInfosString = sessionStorage.getItem("@userInfos");
  let userInfos = null;
  if(userInfosString){
    userInfos = JSON.parse(userInfosString);
  }

  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState(/*productsList*/[]);
  const [users, setUsers] = useState([]);

  const [productDeletedSnackbarOpened, setProductDeletedSnackbarOpened] = useContext(ProductDeletedSnackbarContext);

  const [warningAlertData, setWarningAlertData] = useState({
    opened: false,
    text: null,
    confirmButtonName: null,
    product: null
  });

  const [contactSupplierDialogFormData, setContactSupplierDialogFormData] = useState({
    opened: false,
    product: null
  });

  // Supprimer un produit ==============================
  const deleteImage = async (productId, index) => {
    await deleteObject(ref(storage, `products/${productId}/image${index+1}.jpg`))
    .then(() => null)
    .catch((err) => null)
  }
  const deleteProduct = useCallback((product) => {
    (async () => {
      Promise.all(product.images.map((_, index) => deleteImage(product.productId, index)))
      .then(async () => {
        return await deleteDoc(doc(db, "products", product.productId))
      })
      .then(() => {
        setProducts(products => products.filter((productIterated, index) => productIterated.productId != product.productId));
        setWarningAlertData({opened:false, text:null, confirmButtonName:null, productId:null});
        setProductDeletedSnackbarOpened(productDeletedSnackbarOpened => true);
      })
      .catch((err) => console.log(err))
    })()
  }, [warningAlertData]);

  const navigateTo = useNavigate();

  useEffect(() => {
    setLoading(loading => true);
    (async () => {
      await getDocs(query(collection(db, "products"), limit(4), orderBy("createdAt", "desc")))
      .then((productsDocs) => {
        let productsList = [];
        productsDocs.forEach(doc => productsList.push(doc.data()));
        setProducts(products => productsList);
        setLoading(loading => false);
      })
      .catch((err) => setLoading(loading => false))

      await getDocs(query(collection(db, "users"), where("accounttype", "==", "Fournisseur"), limit(4), orderBy("createdAt", "desc")))
      .then((usersDocs) => {
        let usersList = [];
        usersDocs.forEach(doc => usersList.push(doc.data()));
        setUsers(users => usersList);
        setLoading(loading => false);
      })
      .catch((err) => setLoading(loading => false))
    })()
  }, [])

  return (
    <div className='page-container'>

     <div className="page-body">
        <div className='page-content'>

          <div className="header-banner-media-container">
            <HomeCarousel/>
            <div className='home-page-label' style={{position:'absolute', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.4)', marginBottom:95}}>
              <div style={{width:'100%', boxSizing:'border-box', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <h4>AQSO</h4>
                <span>La plateforme de sourcing du made in Africa.</span>
                <div/>
                <SearchForm width={{xs:280, md:500}} height={{xs:25, md:40}} buttonWidth={{xs:12.5, md:25}} fontSize={{xs:10, md:16}} placeholder={"EX: Bissap"} buttonColor="#FF6A00" iconColor="black" buttonHoverColor="orange" onSubmit={(event) => {event.preventDefault(); if(event.target.search.value.replace(/\s/g, '').length === 0){navigateTo("/Fournisseurs")}else{navigateTo("/Produits?search=" + event.target.search.value)}}} required={false}/>
              </div>
            </div>
          </div>

          <div className='home-page-container'>
            <section className='elements-section'>
              <div className='elements-title-subtitle'>
                <h4>Catégories</h4>
                <span>Découvrez les catégories de produits disponible sur AQSO.</span>
                <div/>
              </div>
              <div className="products-categories-container">
               <Stack sx={{display:'flex', flexDirection:'column', flexGrow:1, height:{xs:'60vw', lg:'400px'}, justifyContent:'center', alignItems:'center', gap:{xs:'0.9vw', lg:'0.5vw'}}}>
                <CategoryCard width="100%" height="100%" data={productsCategories[0]} onClick={() => navigateTo("/Produits?Categories=Matières+premières")}/>
                <CategoryCard width="100%" height="100%" data={productsCategories[1]} onClick={() => navigateTo("/Produits?Categories=Produits+dérivés")}/>
               </Stack>
               <Stack sx={{display:'flex', flexDirection:'column', width:{xs:'100vw', lg:'350px'}, height:{xs:'60vw', lg:'400px'}, justifyContent:'center', alignItems:'center', gap:'2vw'}}>
                <CategoryCard width="100%" height="100%" data={productsCategories[2]} onClick={() => navigateTo("/Produits?Categories=Autres")}/>
               </Stack>
              </div>
            </section>

            {/*<section className='panel-separator' style={{backgroundImage:"url('/public_assets/Home_Carousel/Home_Carousel_Image_4.jpg')"}}>
              <div style={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', borderRadius:'10px', justifyContent:'center', alignItems:'center', backdropFilter:'blur(0px)'}}>
                <div style={{backdropFilter:'blur(10px)', padding:'30px 50px 30px 50px', border:'solid white 0px', backgroundColor:'rgba(0,0,0,0.4)'}}>
                  <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Rejoignez AQSO !</h4>
                  <span style={{color:'white', fontSize:'18px'}}>Rejoignez AQSO en tant que fournisseur.</span>
                  <div style={{width:'100px', height:'4px', backgroundColor:'#FF6A00', marginTop:'15px', marginBottom:'20px'}}/>
                  <Button startIcon={<AddBusinessIcon/>} sx={{textTransform: 'none', backgroundColor:"#012d6e", color:"white", fontWeight:'bold', height:40, width:200, borderRadius:2, "&:hover":{backgroundColor:"#0053CF"}}} onClick={() => navigateTo("/DevenirFournisseur")}>
                    Devenir fournisseur
                  </Button>
                </div>
              </div>
            </section>*/}

            <section className='elements-section'>
              <div className='elements-title-subtitle'>
                <h4>Nouveautés</h4>
                <span>Découvrez les produits sélectionnés par AQSO.</span>
                <div/>
              </div>
              <div className="elements-container">
               {loading && (new Array(4).fill(0)).map((item, index) => <ProductCardSkeleton/>)}
               {!loading && <>
                 {products.length !== 0 && products/*.slice(0,24)*/.map((item, index) => <ProductCard key={index} index={index} productInfos={item} onClickContactSupplier={() => setContactSupplierDialogFormData({opened:true, product:item})} onClickDelete={() => setWarningAlertData({opened:true, text:'Êtes-vous sûr de vouloir supprimer ce produit ?', confirmButtonName:'Supprimer', product:item})}/>)}
                 {products.length === 0 && <div className="empty-box-container">
                   <img src="/public_assets/Extra_Icons/icone_boite_vide.png" alt="empty-box"/>
                 </div>}
               </>}
              </div>
            </section>

            <section className='panel-separator' style={{backgroundImage:"url('/public_assets/Home_Carousel/Home_Carousel_Image_4.jpg')"}}>
              {/*<div style={{position:'absolute', display:'flex', flexDirection:'column', width:'100%', height:'100%', borderRadius:'10px', justifyContent:'center', alignItems:'center', backdropFilter:'blur(0px)'}}>*/}
                <div style={{backdropFilter:'blur(10px)', padding:'30px 50px 30px 50px', borderRadius:'10px', border:'solid white 0px', backgroundColor:'rgba(0,0,0,0.4)'}}>
                  <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Rejoignez AQSO !</h4>
                  <span style={{color:'white', fontSize:'18px'}}>{(auth?.currentUser && userInfos?.accounttype === "Fournisseur") ? "Gérez votre catalogue et boostez vos ventes à l'international." : (auth?.currentUser && userInfos?.accounttype === "Acheteur") ? "Trouvez exactement ce dont vous avez besoin auprès de nos fournisseurs de produits authentiques." : "Améliorez vos ventes à l'internationale avec AQSO."}</span>
                  <div style={{width:'100px', height:'4px', backgroundColor:'#FF6A00', marginTop:'15px', marginBottom:'20px'}}/>
                  <Button startIcon={!auth?.currentUser ? <AddBusinessIcon/> : <ShoppingBasketIcon/>} sx={{textTransform: 'none', backgroundColor:"#012d6e", color:"white", fontWeight:'bold', height:40, width:!auth?.currentUser ? 200 : 250, borderRadius:2, "&:hover":{backgroundColor:"#0053CF"}}} onClick={() => {!auth?.currentUser ? navigateTo("/DevenirFournisseur") : navigateTo("/Produits")}}>
                    {!auth?.currentUser ? "Devenir fournisseur" : "Découvrir plus de produits"}
                  </Button>
                </div>
              {/*</div>*/}
            </section>

            <section className='elements-section'>
              <div className='elements-title-subtitle'>
                <h4>Fournisseurs</h4>
                <span>Découvrez les fournisseurs sélectionnés par AQSO.</span>
                <div style={{width:'140px'}}/>
              </div>
              <div className="elements-container">
               {loading && (new Array(4).fill(0)).map((item, index) => <SupplierCardSkeleton/>)}
               {!loading && <>
                 {users.length !== 0 && users/*.slice(0,24)*/.map((item, index) => <SupplierCard key={index} index={index} supplierInfos={item}/>)}
                 {users.length === 0 && <div className="empty-box-container">
                   <img src="/public_assets/Extra_Icons/icone_boite_vide.png" alt="empty-box"/>
                 </div>}
               </>}
              </div>
            </section>
          </div>

        </div>
        <Footer/>
      </div>
      {/*<Fab disabled aria-label="like">
        <FavoriteIcon />
      </Fab>*/}
      <LFWarningAlert opened={warningAlertData.opened} text={warningAlertData.text} confirmButtonName={warningAlertData.confirmButtonName} handleConfirm={() => deleteProduct(warningAlertData.product)} handleCancel={() => setWarningAlertData({opened:false, text:null, confirmButtonName:null, product:null})}/>
      <LFContactSupplierDialogForm opened={contactSupplierDialogFormData.opened} handleClose={() => setContactSupplierDialogFormData({opened:false, product:null})} targetProduct={contactSupplierDialogFormData.product}/>

    </div>
  )
}
