//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/MyProductsPage.css';
import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
//import MyCartData from '../JSON/MyCartData';
import LFModalView from '../LeoFarataFrameWorks/LFModalView';
import LFWarningAlert from '../LeoFarataFrameWorks/LFWarningAlert';
import LFContactSupplierDialogForm from '../LeoFarataFrameWorks/LFContactSupplierDialogForm';
import { ProductDeletedSnackbarContext } from '../Contexts';
import ProductCard from '../LeoFarataFrameWorks/ProductCard';
import ProductCardSkeleton from '../LeoFarataFrameWorks/ProductCardSkeleton';
import {useState, useEffect, useCallback, useContext} from 'react';
import {auth, db, getDocs, collection, where, query, deleteDoc, deleteObject, ref, storage, doc} from '../Firebase/firebase';

export default function MyProductsView(){

  const [myProducts, setMyProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  const [productDeletedSnackbarOpened, setProductDeletedSnackbarOpened] = useContext(ProductDeletedSnackbarContext);

  const [warningAlertData, setWarningAlertData] = useState({
    opened: false,
    text: null,
    confirmButtonName: null,
    product: null
  });

  // Supprimer un produit ==============================
  const deleteImage = async (productId, index) => {
    await deleteObject(ref(storage, `products/${productId}/image${index+1}.jpg`))
    .then(() => null)
    .catch((err) => null)
  }
  const deleteProduct = useCallback((product) => {
    (async () => {
      Promise.all(product.images.map((_, index) => deleteImage(product.productId, index)))
      .then(async () => {
        return await deleteDoc(doc(db, "products", product.productId))
      })
      .then(() => {
        setMyProducts(products => myProducts.filter((productIterated, index) => productIterated.productId != product.productId));
        setWarningAlertData({opened:false, text:null, confirmButtonName:null, productId:null});
        setProductDeletedSnackbarOpened(productDeletedSnackbarOpened => true);
      })
      .catch((err) => console.log(err))
    })()
  }, [warningAlertData]);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if(user){
        await getDocs(query(collection(db, "products"), where("supplierId", "==" , auth.currentUser.uid)))
        .then((docs) => {
          let myProductsList = [];
          docs.forEach(doc => myProductsList.push(doc.data()));
          setMyProducts(myProducts => myProductsList);
          setLoading(loading => false);
        })
        .catch((err) => console(err))
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Banner_Background_Image_1.png" title="Mes Produits" subtitle="Sur cette page, gérez les produits que vous avez mis en ligne sur AQSO." labelWidth="100%"/>
        <div className='page-content'>
          <div className="myproducts-page">
            <div className="myproducts-container">
             {loading && (new Array(4).fill(0)).map((item, index) => <ProductCardSkeleton/>)}
             {!loading && myProducts/*.slice(0,24)*/.map((item, index) => <ProductCard key={index} index={index} productInfos={item} onClickDelete={() => setWarningAlertData({opened:true, text:'Êtes-vous sûr de vouloir supprimer ce produit ?', confirmButtonName:'Supprimer', product:item})}/>)}
            </div>
          </div>
         </div>
         <Footer/>
      </div>

      <LFWarningAlert opened={warningAlertData.opened} text={warningAlertData.text} confirmButtonName={warningAlertData.confirmButtonName} handleConfirm={() => deleteProduct(warningAlertData.product)} handleCancel={() => setWarningAlertData({opened:false, text:null, confirmButtonName:null, product:null})}/>

    </div>
  )
}
