import SearchForm from './LeoFarataFrameWorks/SearchForm';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { SuccessNewsLetterSubscriptionSnackbarContext} from './Contexts';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {db, setDoc, doc} from './Firebase/firebase';
import LFContactUsDialogForm from './LeoFarataFrameWorks/LFContactUsDialogForm';
import LFSupportContactDialogForm from './LeoFarataFrameWorks/LFSupportContactDialogForm';

import '../Styles/Footer.css';


export default function Footer(){

  const navigateTo = useNavigate();

  const [successNewsLetterSubscriptionSnackbarOpened, setSuccessNewsLetterSubscriptionSnackbarOpened] = useContext(SuccessNewsLetterSubscriptionSnackbarContext);
  const [contactUsDialogFormOpened, setContactUsDialogFormOpened] = useState(false);
  const [supportContactDialogFormOpened, setSupportContactDialogFormOpened] = useState(false);

  const subscribeToNewsLetter = async (event) => {
    await setDoc(doc(db, "newsletterMailingList", event.target.email.value), {email: event.target.email.value})
    .then(() => {
      //console.log("Subscribed to the newletter !");
      document.getElementById("newsletter-bar").value = "";
      setSuccessNewsLetterSubscriptionSnackbarOpened(successNewsLetterSubscriptionSnackbarOpened => true);
    })
    .catch((err) => null)
  }

  return (
    <footer className='footer'>

      <div className="footer-content">
        <div className="footer-section">
          <div className='site-logo'>
            <img src="/public_assets/logo_aqso_blanc_orange.png" alt="Company Logo"/>
          </div>
        </div>
        {/*<div className="footer-section">
          <h4>À propos</h4>
          <div className="footer-section-title-underline"/>
          <div style={{display:'flex', flexDirection:'column', marginTop:20}}>
            <span style={{color:'white'}}>Africa Quality Sourcing</span>
            <span style={{color:'white'}}>123456 Rue Quartier</span>
            <span style={{color:'white'}}>Ville Pays</span>
            <span style={{color:'white', cursor:'pointer'}} onClick={() => window.open("mailto:contact@aqso.com")}>Email: contact@aqso.com</span>
          </div>
        </div>*/}

        <div className="footer-section">
          <h4>À propos</h4>
          <div className="footer-section-title-underline"/>
          <ul>
            <li><a onClick={() => navigateTo("/Apropos")}>Équipe</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Services</h4>
          <div className="footer-section-title-underline"/>
          <ul>
            <li><a onClick={() => setContactUsDialogFormOpened(contactUsDialogFormOpened => true)}>Contact</a></li>
            <li><a onClick={() => setSupportContactDialogFormOpened(supportContactDialogFormOpened => true)}>Support</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Adresse</h4>
          <div className="footer-section-title-underline"/>
          <ul>
            <li><span>Liberté 6 extension</span></li>
            <li><span>Près d'Auchan</span></li>
            <li><span>Dakar, Sénégal</span></li>
          </ul>
        </div>

        <div className="footer-section" style={{display:'flex', flexDirection:'column'}}>
        <h4>Newsletter</h4>
        <div className="footer-section-title-underline"/>
          <SearchForm width={300} height={35} marginTop={2} inputType="email" inputName="email" inputId="newsletter-bar" placeholder={"Email"} buttonText="S'abonner" buttonTextColor="white" buttonColor="#FF6A00" iconColor="black" buttonHoverColor="orange" onSubmit={(event) => {event.preventDefault(); subscribeToNewsLetter(event)}}/>
        </div>
      </div>
      <div className="footer-bottom">
        <div className='poweredby-logo' onClick={() => window.open("https://leofarata.com/", "_Blank")}>
          {/*<p>Dévéloppé par :</p>*/}
          <img src="/public_assets/logo_leofarata_technologies.png" alt="Company Logo"/>
        </div>
        <div className='footer-copyright'>
          <p style={{color:'white', fontSize:14}}>Copyright © 2024 AQSO. Tous droits réservés.</p>
        </div>
        <div className="footer-contacts">
          <IconButton type='button' sx={{width:30, height:30, p:'15px', backgroundColor:'white', "&:hover":{backgroundColor:'white', boxShadow:'#FF6A00 0px 0px 20px 2.5px', "& .contacts-icon":{color:'#FF6A00'}}}} onClick={() => window.open("mailto:africaqualitysourcing@gmail.com")}>
            <MailIcon className="contacts-icon" sx={{color:'black', width:20}}/>
          </IconButton>
          {/*<IconButton type='button' sx={{width:30, height:30, p:'15px', backgroundColor:'white', "&:hover":{backgroundColor:'white', boxShadow:'#FF6A00 0px 0px 20px 2.5px', "& .contacts-icon":{color:'#FF6A00'}}}} onClick={() => window.open("tel:+221784758756")}>
            <PhoneIcon className="contacts-icon" sx={{color:'black', width:20}}/>
          </IconButton>*/}
          <IconButton type='button' sx={{width:30, height:30, p:'15px', backgroundColor:'white', "&:hover":{backgroundColor:'white', boxShadow:'#FF6A00 0px 0px 20px 2.5px', "& .contacts-icon":{color:'#FF6A00'}}}} onClick={() => window.open("https://www.linkedin.com/company/africa-quality-sourcing-aqso/?trk=public_post-text&originalSubdomain=snhttps://www.linkedin.com/company/africa-quality-sourcing-aqso/?trk=public_post-text&originalSubdomain=sn")}>
            <LinkedInIcon className="contacts-icon" sx={{color:'black', width:20}}/>
          </IconButton>
          <IconButton type='button' sx={{width:30, height:30, p:'15px', backgroundColor:'white', "&:hover":{backgroundColor:'white', boxShadow:'#FF6A00 0px 0px 20px 2.5px', "& .contacts-icon":{color:'#FF6A00'}}}} onClick={() => window.open("https://www.instagram.com/africa_quality_sourcing/")}>
            <InstagramIcon className="contacts-icon" sx={{color:'black', width:20}}/>
          </IconButton>
        </div>
      </div>

      <LFContactUsDialogForm opened={contactUsDialogFormOpened} handleClose={() => setContactUsDialogFormOpened(contactUsDialogFormOpened => false)}/>
      <LFSupportContactDialogForm opened={supportContactDialogFormOpened} handleClose={() => setSupportContactDialogFormOpened(supportContactDialogFormOpened => false)}/>

    </footer>
  )
}
