import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useNavigate } from 'react-router-dom';

export default function CategoryCard({width, height, data, onClick}) {

  const navigateTo = useNavigate();

  return (
    <>
      <ImageListItem sx={{ display:'flex', flex: height ?? 1, width:width ?? '250px', height:height ?? '350px', boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", borderRadius:2, border:"solid white 3px", "&:hover":{cursor:"pointer", border:"solid #FF6A00 3px", boxShadow:"#FF6A00 0px 0px 30px 3.75px"}, overflow:'hidden' }} onClick={() => onClick()}>
        <img src={data.image} alt={data.title} loading="lazy" style={{width:width ?? '250px', height:height ?? '350px', borderRadius:2/*, objectFit:'fill'*/}}/>
        <ImageListItemBar
          sx={{background:'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'}}
          title={data.title}
          position="top"
          actionIcon={<CategoryOutlinedIcon sx={{color:'white', p:1}}/>}
          actionPosition="left"
        />
      </ImageListItem>
    </>
  );
}
