import '../../Styles/Frameworks_Styles/LFModalView.css';
import {memo} from 'react';

function LFModalView({content, onClose}){
  return(
    <div className="modal-view" onClick={onClose}>
      <div className="modal-container">

       <div style={{width:'100%', height:'100%'}} onClick={(event) => event.stopPropagation()}>
         {content}
       </div>

       <div className="modal-close-button">
         <span style={{color:'black', fontWeight:'bold'}}>X</span>
       </div>

      </div>
    </div>
  );
}

export default memo(LFModalView);
