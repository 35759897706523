import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TextField from '@mui/material/TextField';
import {useContext, useState} from "react";
import { ResetPasswordEmailSentSnackbarContext } from '../Contexts';
import {auth, sendPasswordResetEmail} from '../Firebase/firebase';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function LFResetPasswordDialogForm({opened, handleClose}) {

 const [loading, setLoading] = useState(false);

 const [resetPasswordEmailSentSnackbarOpened, setResetPasswordEmailSentSnackbarOpened] = useContext(ResetPasswordEmailSentSnackbarContext);

 let email = "";

 const resetPassword = async () => {
   setLoading(loading => true);
   await sendPasswordResetEmail(auth, email)
   .then(() => {
     setResetPasswordEmailSentSnackbarOpened(resetPasswordEmailSentSnackbarOpened => true);
     handleClose();
     //setLoading(loading => false);
   })
   .catch((err) => {handleClose(); /*console.log(err)*/});
 }

  return (
    </*form onSubmit={(event) => {event.preventDefault(); resetPassword(event)}}*/>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        sx={{"& .MuiDialog-paper":{borderRadius:2, overflow:'hidden'}}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center">
            <KeyRoundedIcon sx={{width:30, height:30, color:'#0053CF'}}/>
            <span>Mot de passe oublié ?</span>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack sx={{width:'100%'}} spacing={2}>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <Typography variant="body1" sx={{ textTransform: 'none', color:'gray', textAlign:'justify', width:'400px'}}>Nous vous enverrons un email de réinitialisation de mot de passe.</Typography>
            </Stack>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField fullWidth onChange={(event) => {email = event.target.value}} sx={{backgroundColor:'#f5f9ff', borderRadius:2, width:'400px', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type='email' id="email" name="email" label="Email" placeholder="Email" required/>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            endIcon={<LockResetRoundedIcon/>}
            sx={{color:'white', backgroundColor:'#168000', borderRadius:2, boxShadow:'none', "&:hover":{backgroundColor:'#0c4500'}}}
            loadingIndicator={<CircularProgress color="inherit" size={22}/>}
            loading={loading}
            /*loadingPosition="end"*/
            variant="contained"
            type="submit"
            /*autoFocus*/
            onClick={resetPassword}
          >
            Réinitialiser
          </LoadingButton>
          <Button endIcon={<CancelRoundedIcon/>} /*autoFocus*/ onClick={handleClose} sx={{color:'white', backgroundColor:'#0053CF', borderRadius:2, "&:hover":{backgroundColor:'#012d6e'}}}>
            Annuler
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ /*form*/>
  );
}
