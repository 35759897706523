//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/AboutPage.css';
import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
import LFModalView from '../LeoFarataFrameWorks/LFModalView';
import {useState, /*useEffect,*/ useRef} from 'react';

export default function AboutView(){

  const [artworkModalVisible, setArtworkModalVisible] = useState(false);
  const selectedItem = useRef('');

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur5.png" title="À propos" subtitle="Sur cette page, découvrez l'histoire et la mission de AQSO." labelWidth="100%"/>
        <div className='page-content'>
          <div className="about-container">

          {/*<div className="section">
            <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Histoire</h4>
            <div style={{width:'60px', height:'4px', backgroundColor:'#FF6A00', marginTop:'10px'}}/>
            <p>La genèse d'AQSO est enracinée dans nos souvenirs d'enfance, profondément marqués par les saveurs culinaires de différents pays. Cette nostalgie nous incite souvent à cuisiner des mets rappelant ces années. Récemment, une envie de savourer un plat traditionnel à base de semoule de blé et d’épices s'est manifestée. Bien que nous savions déjà que ces produits étaient disponibles dans les épiceries spécialisées, des recherches plus approfondies nous ont révélé une dynamique croissante autour des produits de notre enfance, ces produits exotiques africains.</p>
            <p>Cette prise de conscience a inspiré la création d'AQSO en 2024, une plateforme de sourcing B2B dédiée à faciliter l'accès aux produits exotiques africains de qualité. AQSO connecte efficacement les meilleurs fournisseurs africains avec des entreprises internationales, valorisant ainsi la richesse culinaire africaine et promouvant le commerce équitable. En simplifiant les transactions et en assurant la qualité des produits, AQSO contribue à promouvoir les produits africains et à stimuler la croissance économique de la région.</p>
          </div>

          <div className="section">
            <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Mission et Vision</h4>
            <div style={{width:'100px', height:'4px', backgroundColor:'#FF6A00', marginTop:'10px'}}/>
            <p>Notre mission: Promouvoir le Made in Africa</p>
            <p>AQSO est déterminée à valoriser les produits africains en mettant en avant la richesse et la diversité de la culture locale. Nous travaillons exclusivement avec des fournisseurs authentiques pour garantir que chaque produit sur notre plateforme est de la plus haute qualité et de la plus grande authenticité. Nous souhaitons montrer et apprécier les produits uniques africains à travers le monde, contribuant ainsi à l’essor économique de la région.</p>
            <p>Favoriser le Circuit Court: Notre plateforme est conçue pour connecter directement les entreprises africaines avec les entreprises internationales. En réduisant le nombre d'intermédiaires, nous simplifions le processus de transaction et assurons une plus grande transparence et efficacité. Cette approche permet non seulement de soutenir les producteurs locaux, mais aussi de garantir aux acheteurs des produits frais de haute qualité, tout en contribuant à la durabilité de l'écosystème.</p>
            <p>Accès Simplifié à une Large Gamme de Produits: AQSO offre une plateforme unique où les entreprises internationales peuvent accéder facilement à une grande variété de fournisseurs et de produits exotiques africains. En rationalisant l'offre, nous simplifions le sourcing pour nos clients, leur permettant de découvrir et d'acquérir une large gamme de produits authentiques en un seul endroit. Cette diversité et cette accessibilité renforcent notre engagement à fournir des produits de qualité supérieure à nos clients.</p>
          </div>*/}

          <div className="section">
            <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Racines Africaines</h4>
            <div style={{width:'100px', height:'4px', backgroundColor:'#FF6A00', marginTop:'10px'}}/>
            <p>La genèse d'AQSO est enracinée dans nos souvenirs d'enfance, profondément marqués par les saveurs culinaires de différents pays. Cette nostalgie nous incite souvent à cuisiner des mets rappelant ces années. Récemment, une envie de savourer un plat traditionnel à base de semoule de blé et d’épices s'est manifestée. Bien que nous savions déjà que ces produits étaient disponibles dans les épiceries spécialisées, des recherches plus approfondies nous ont révélé une dynamique croissante autour des produits de notre enfance, ces produits exotiques africains.</p>
            <p>Cette prise de conscience a inspiré la création d'AQSO en 2024, une plateforme de sourcing B2B dédiée à faciliter l'accès aux produits exotiques africains de qualité. AQSO connecte efficacement les meilleurs fournisseurs africains aux entreprises internationales, valorisant ainsi la richesse culinaire africaine et promouvant le commerce équitable. En simplifiant les transactions et en assurant la qualité des produits, AQSO contribue à promouvoir les produits africains et à stimuler la croissance économique du continent.</p>
          </div>

          <div className="section">
            <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Promouvoir le Made in Africa et bien plus</h4>
            <div style={{width:'200px', height:'4px', backgroundColor:'#FF6A00', marginTop:'10px'}}/>
            <p>Chez AQSO, nous sommes profondément engagés à valoriser les produits africains en mettant en lumière la richesse et la diversité de la culture locale. Nous collaborons étroitement avec des fournisseurs authentiques afin de garantir que chaque produit proposé sur notre plateforme incarne la plus haute qualité et l'authenticité africaine. Notre objectif est de présenter ces produits uniques au monde entier, contribuant ainsi à l’essor économique et à la reconnaissance internationale des savoir-faire locaux.</p>
            <p>Ensuite, notre plateforme est spécifiquement conçue pour établir des connexions directes entre les entreprises africaines et les marchés internationaux, favorisant ainsi le circuit court. En réduisant les intermédiaires, nous simplifions les transactions, assurant ainsi une plus grande transparence et une efficacité accrue. Cette approche soutient non seulement les producteurs locaux, mais garantit également aux acheteurs des produits de haute qualité, frais et authentiques, tout en promouvant la durabilité de l'écosystème économique africain.</p>
            <p>Enfin, AQSO se positionne comme une passerelle unique permettant aux entreprises internationales de découvrir et d'accéder facilement à une vaste gamme de produits exotiques africains. En rationalisant l'offre, nous facilitons le sourcing pour nos clients, leur offrant la possibilité de découvrir une grande diversité de produits authentiques en un seul lieu. Cette variété et cette accessibilité sont au cœur de notre engagement à fournir des produits de qualité supérieure qui reflètent la richesse et l'ingéniosité du continent africain.</p>
          </div>

          <div className="section">
            <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Nos Valeurs</h4>
            <div style={{width:'70px', height:'4px', backgroundColor:'#FF6A00', marginTop:'10px'}}/>
            <p>L'innovation est une de nos valeurs clés. Nous cherchons constamment à innover et à améliorer notre plateforme pour répondre aux besoins évolutifs de nos utilisateurs. Cela implique d'intégrer des technologies de pointe et de trouver des solutions créatives aux défis que nous rencontrons.</p>
            <p>* La durabilité est une autre valeur essentielle pour nous. Nous nous engageons à promouvoir des pratiques durables et éthiques, tant dans notre entreprise que parmi nos fournisseurs. Cela inclut le soutien aux fournisseurs biologiques et équitables, ainsi que la réduction de notre impact environnemental.</p>
            <p>* La solidarité est une valeur fondamentale chez AQSO. Nous croyons fermement en la coopération et le soutien mutuel, tant au sein de notre équipe qu'avec nos partenaires et fournisseurs. Cela crée un environnement de travail harmonieux et productif, où chacun peut apporter le meilleur de lui-même.</p>
            <p>* Le travail bien fait est au cœur de tout ce que nous faisons. Nous nous efforçons de dépasser les attentes en offrant des produits et services de la plus haute qualité. Cette exigence de qualité se reflète dans chaque aspect de notre activité, de la sélection des produits à la satisfaction des clients.</p>
          </div>

            <div className="section">
                <h4 style={{color:'#FF6A00', fontSize:'28px', fontWeight:'bold', margin:'0px'}}>Équipe</h4>
                <div style={{width:'55px', height:'4px', backgroundColor:'#FF6A00', marginTop:'10px', marginBottom:'25px'}}/>
                <div className="team">
                    <div className="team-member">
                        <img src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Fphoto_de_profil_par_defaut.jpg?alt=media&token=032819d5-e344-4684-b0dc-c69e2c590901" alt="Assitan Diarra"/>
                        <h3>Assitan Diarra, Fondatrice et CEO</h3>
                        <p>Assitan est une ingénieure spécialisée en Data Science avec un mastère 2 en la matière. Avec plus de 7 ans d'expérience chez Accor, aussi de l'équipe Data science & AI, elle a piloté des projets d'envergure et dirigé une équipe de 7 personnes auprès de la VP de son équipe. Sa passion pour les produits exotiques africains et son expertise en gestion de projets technologiques sont les moteurs derrière AQSO.</p>
                    </div>
                    <div className="team-member">
                        <img src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Fphoto_de_profil_par_defaut.jpg?alt=media&token=032819d5-e344-4684-b0dc-c69e2c590901" alt="Serata Koulnan"/>
                        <h3>Serata Koulnan, Project Manager</h3>
                        <p>Serata Koulnan, project manager avec une expérience de 3 ans dans le domaine de l'informatique et du management, apporte son savoir-faire dans les tâches comme le suivi et le contrôle du budget des projets.</p>
                    </div>
                    <div className="team-member">
                        <img src="https://firebasestorage.googleapis.com/v0/b/leofarata-technologies.appspot.com/o/Images%2FArtworks%2FSnapinsta.app_336960680_988632842547033_8808856301800368234_n_1080.jpg?alt=media&token=88cf16c7-6855-4869-913f-533df9ce9d80" alt="Serata Koulnan"/>
                        <h3>Arafat FEICAL IDRISSA, Computer Science Engineer</h3>
                        <p>Arafat Feical Idrissa, Computer Science Engineer, met à profit son expertise dans le développement de solutions technologiques innovantes. Avec une solide maîtrise des concepts de génie informatique, il se distingue par sa capacité à résoudre des problèmes complexes et à optimiser les performances des systèmes pour des projets variés.</p>
                    </div>
                </div>
            </div>

          </div>
         </div>
         <Footer/>
      </div>

      {artworkModalVisible && <LFModalView content={<img style={{width:'100%', height:'100%', objectFit:'contain', objectPosition:'center'}} src={selectedItem.current.image} alt={selectedItem.current.alt}/>} onClose={() => setArtworkModalVisible(artworkModalVisible => false)}/>}

    </div>
  )
}
