import '../../Styles/Frameworks_Styles/ActivityIndicator.css';
import {memo} from 'react';

function ActivityIndicator(){
  //const [play, setPlay] = useState(false);
  return(
    <div className="activity-indicator-container">
     <div className="activity-indicator"/>
    </div>
  );
}

export default memo(ActivityIndicator);
