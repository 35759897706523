//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/OtherUserProfilPage.css';
import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
//import NotificationData from '../JSON/NotificationData';
//import NotificationCard from '../LeoFarataFrameWorks/NotificationCard';
import {useState, useEffect, useContext, useCallback} from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Rating from '@mui/material/Rating';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from "lodash";
import {db, getDocs, collection, where, query, orderBy} from '../Firebase/firebase';
import ProductCard from '../LeoFarataFrameWorks/ProductCard';
import ProductCardSkeleton from '../LeoFarataFrameWorks/ProductCardSkeleton';
import LFContactSupplierDialogForm from '../LeoFarataFrameWorks/LFContactSupplierDialogForm';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';

export default function SupplierDetailsView(){

  const location = useLocation();

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 35,
    height: 35,
    color:'#0053CF',
    border: `5px solid ${theme.palette.background.paper}`
  }));

  const [contactSupplierDialogFormData, setContactSupplierDialogFormData] = useState({
    opened: false,
    product: null
  });

  const [products, setProducts] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [value, setValue] = useState('1');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [artworkModalVisible, setArtworkModalVisible] = useState(false);

  const [userInfos, setUserInfos] = useState(location.state);

  const [dataHasChanged, setDataHasChanged] = useState(false);

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async () => {
      // Recherche des produits de l'utilisateur
      await getDocs(query(collection(db, "products"), where("supplierId", "==" , userInfos.userId), orderBy("createdAt", "desc")))
      .then((docs) => {
        let productsList = [];
        docs.forEach(doc => productsList.push(doc.data()));
        setProducts(products => productsList);
        setLoading(loading => false);
      })
      .catch((err) => console(err))
    })();
  }, []);

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur4.png" title="Profil" subtitle="Sur cette page, les informations sur votre profil." labelWidth="100%"/>
        <div className='page-content'>
          <div className="profil-container">

           {/*NotificationData/*.slice(0,24)*//*.map((item, index) => <NotificationCard key={index} index={NotificationData.length - index} image={item.image} title={item.title} description={item.description} alt={item.alt} onClick={() => {selectedItem.current = item; setArtworkModalVisible(artworkModalVisible => true)}}/>)*/}

             <Stack direction="column" spacing={3} sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', boxSizing:'border-box'}}>
               <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                 <Paper sx={{width:'100%', height:300, padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                   <Stack spacing={2} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <Badge
                       invisible={!userInfos?.isVerified}
                       overlap="circular"
                       anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                       badgeContent={
                         <SmallAvatar alt="Verified Icon" src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Ficone_certifi%C3%A9.png?alt=media&token=846584e0-0d0f-425d-ab5d-eb12fd5bf2f7" />
                       }
                     >
                       <Avatar sx={{width:120, height:120, border: `8px solid black`}} alt="Profil Image" src={userInfos.profilPicture}/>
                     </Badge>

                     <Typography variant="h5" sx={{textAlign:'center'}}>{userInfos.username}</Typography>
                     <Typography variant="body1" color="text.secondary" sx={{textAlign:'center'}}>{userInfos.accounttype}</Typography>
                     <Rating name="rating" value={userInfos.rating} readOnly/>
                   </Stack>
                 </Paper>
               </div>

               <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                 <Paper sx={{width:'100%', padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                   <Box elevation={1} sx={{ width: '100%', typography: 'body1', borderRadius:5 }}>
                     <TabContext value={value}>
                       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                         <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{"& .MuiTabs-indicator":{backgroundColor:'#FF6A00'}}} variant="fullWidth">
                           {/*<Tab label="Description" value="1" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00'}}}/>*/}
                           <Tab label="Produits" value="1" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00', background:'#fcf7f2', borderRadius:'10px'}}}/>
                         </TabList>
                       </Box>
                     </TabContext>
                   </Box>
                 </Paper>
               </div>


               <TabContext value={value}>
                 <TabPanel value="1" sx={{p:0, width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                   {/*<Paper sx={{width:'100%', height:600, padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center', boxSizing:'border-box', overflow:'hidden'}} elevation={2}>
                   </Paper>*/}
                   <Stack spacing={5}>
                     <div className="products-container">
                      {loading && (new Array(8).fill(0)).map((item, index) => <ProductCardSkeleton/>)}
                      {!loading && <>
                        {products.length !== 0 && products/*.slice(0,24)*/.map((item, index) => <ProductCard key={index} index={index} productInfos={item} onClickContactSupplier={() => setContactSupplierDialogFormData({opened:true, product:item})}/>)}
                        {products.length === 0 && <div className="empty-box-container">
                          <img src="/public_assets/Extra_Icons/icone_boite_vide.png" alt="empty-box"/>
                        </div>}
                      </>}
                     </div>
                   </Stack>
                 </TabPanel>
               </TabContext>

             </Stack>

          </div>
         </div>
         <Footer/>
      </div>

      <LFContactSupplierDialogForm opened={contactSupplierDialogFormData.opened} handleClose={() => setContactSupplierDialogFormData({opened:false, product:null})} targetProduct={contactSupplierDialogFormData.product}/>

    </div>
  )
}
