import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TextField from '@mui/material/TextField';
import {useContext, useState} from "react";
import { MessageSentSnackbarContext } from '../Contexts';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function LFSupportContactDialogForm({opened, handleClose}) {

  const [loading, setLoading] = useState(false);

  let userInfosString = sessionStorage.getItem("@userInfos");
  let userInfos = null;
  if(userInfosString){
    userInfos = JSON.parse(userInfosString);
  }

  const [formData, setFormData] = useState({
    email: userInfos?.email,
    phone: userInfos?.phone,
    firstname: userInfos?.firstname,
    name: userInfos?.name,
    subject: "",
    message: ""
  });

  const [messageSentSnackbarOpened, setMessageSentSnackbarOpened] = useContext(MessageSentSnackbarContext);

  const contactSupport = async () => {
    setLoading(loading => true);
    await axios.post("https://aqso-server-m7nl.onrender.com/MailSender/SupportContact", formData)
    .then(() => {
      setLoading(loading => false);
      setMessageSentSnackbarOpened(messageSentSnackbarOpened => true);
      handleClose();
    })
    .catch(() => {setLoading(loading => false);});
  }

  return (
    </*form onSubmit={(event) => {event.preventDefault(); contactSupport(event)}}*/>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        sx={{"& .MuiDialog-paper":{borderRadius:2, overflow:'hidden'}}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center">
            <ContactSupportRoundedIcon sx={{width:30, height:30, color:'#0053CF'}}/>
            <span>Support</span>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack sx={{width:'100%'}} spacing={2}>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField disabled={userInfos === null ? false : true} defaultValue={userInfos?.email} onChange={(event) => setFormData({...formData, ["email"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type='email' id="email" name="email" label="Email" placeholder="Email" required/>
              <TextField /*disabled={(userInfos === null || userInfos?.phone === null) ? false : true}*/ defaultValue={userInfos?.phone} onChange={(event) => setFormData({...formData, ["phone"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type='tel' id="phone" name="phone" label="Téléphone" placeholder="Téléphone" required/>
            </Stack>

            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField disabled={userInfos === null ? false : true} defaultValue={userInfos?.firstname} onChange={(event) => setFormData({...formData, ["firstname"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="firstname" name="firstname" label="Prénom" placeholder="Prénom" required/>
              <TextField disabled={userInfos === null ? false : true} defaultValue={userInfos?.name} onChange={(event) => setFormData({...formData, ["name"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="name" name="name" label="Nom" placeholder="Nom" required/>
            </Stack>

            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField onChange={(event) => setFormData({...formData, ["subject"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="subject" name="subject" label="Sujet" placeholder="Sujet" required/>
            </Stack>

            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
             <TextField
              onChange={(event) => setFormData({...formData, ["message"]: event.target.value})}
              fullWidth
              multiline
              rows={4}
              maxRows={4}
              sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}}
              error={false}
              id="message"
              name="message"
              label="Message"
              placeholder="Message"
              required/>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            endIcon={<SendRoundedIcon/>}
            sx={{color:'white', backgroundColor:'#168000', borderRadius:2, boxShadow:'none', "&:hover":{backgroundColor:'#0c4500'}}}
            loadingIndicator={<CircularProgress color="inherit" size={22}/>}
            loading={loading}
            /*loadingPosition="end"*/
            variant="contained"
            type="submit"
            /*autoFocus*/
            onClick={contactSupport}
          >
            Envoyer
          </LoadingButton>
          <Button endIcon={<CancelRoundedIcon/>} /*autoFocus*/ /*onClick={handleClose}*/ sx={{color:'white', backgroundColor:'#0053CF', borderRadius:2, "&:hover":{backgroundColor:'#012d6e'}}}>
            Annuler
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ /*form*/>
  );
}
