import {useState, memo} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Home Carousel Image 1',
    imgPath:
      '/public_assets/Home_Carousel/Banniere_1.png',
  },
  {
    label: 'Home Carousel Image 2',
    imgPath:
      '/public_assets/Home_Carousel/Banniere_2.png',
  },
  {
    label: 'Home Carousel Image 3',
    imgPath:
      '/public_assets/Home_Carousel/Banniere_3.png',
  },
  /*{
    label: 'Home Carousel Image 4',
    imgPath:
      '/public_assets/Home_Carousel/Banniere_4.png',
  },*/
];

function HomeCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1 }}>
      {/*<Paper square elevation={0} sx={{ display: 'flex', alignItems: 'center', width: '100%', height: 50, pl: 2, bgcolor: 'background.default' }}>
        <Typography>{images[activeStep].label}</Typography>
      </Paper>*/}
      <AutoPlaySwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents={false}>
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (<Box component="div" sx={{ height: {xs:'45vw', lg:355}, display: 'block', width: '100%', overflow: 'hidden', width: '100%', backgroundImage:`url(${step.imgPath})`, backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat' }} />) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper steps={maxSteps} sx={{ '& .MuiMobileStepper-dotActive': {backgroundColor:"#FF6A00"} }} position="static" activeStep={activeStep} nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} sx={{color:'#FF6A00', "&:hover":{backgroundColor:"#fff5ed"}}}>
            Suivant
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{color:'#FF6A00', "&:hover":{backgroundColor:"#fff5ed"}}}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Précedent
          </Button>
        }
      />
    </Box>
  );
}

export default memo(HomeCarousel);
