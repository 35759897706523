import '../../Styles/Frameworks_Styles/ProductCard.css';
import ActivityIndicator from './ActivityIndicator';
import {useState, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import {auth} from '../Firebase/firebase';

function ProductCard({index, productInfos, onClick, onClickContactSupplier, onClickEdit, onClickDelete}){
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(true);
  const [productNumber, setProductNumber] = useState(1);
  return(
    <Grow in={true}>
      <Card sx={{ width:{xs:'41.8vw', lg:'250px'}, height:{xs:'60vw', lg:'350px'}, boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", borderRadius:2, border:"solid white 3px", "&:hover":{cursor:"pointer", border:"solid #FF6A00 3px", boxShadow:"#FF6A00 0px 0px 30px 3.75px"} }} onClick={() => {!auth?.currentUser ? navigateTo("/ConnexionInscription") : navigateTo("/FicheProduit/" + productInfos.productId, {state:productInfos})}}>
        <CardMedia
          component="img"
          image={productInfos.images[0]}
          alt="Product Image"
          sx={{height:{xs:'37.5vw', lg:"215px"}}}
        />
        <CardContent sx={{p:{xs:'1.8vw', lg:2}}}>
          <Typography gutterBottom variant="h6" component="div" sx={{fontSize:{xs:'3.5vw', lg:"16px"}, maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
            {productInfos.productName}
          </Typography>
          <Typography variant="body2" color="#FF6A00" sx={{fontWeight:'bold', fontSize:{xs:'2.8vw', lg:"14px"}, maxWidth:'100%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
            À partir de {productInfos.minUnitPrice} FCFA / {(productInfos.minUnitPrice / 655).toFixed(2)} €
          </Typography>
        </CardContent>
        <CardActions disableSpacing={false} sx={{display:'flex', justifyContent:auth.currentUser?.uid !== productInfos.supplierId ? 'space-between' : 'flex-end', p:{xs:'1vw', lg:1}}}>
          {auth.currentUser?.uid !== productInfos.supplierId && <Button startIcon={<ChatIcon sx={{width:{xs:'3.5vw', lg:25}}}/>} sx={{textTransform: 'none', backgroundColor:"#0053CF", color:"white", height:{xs:'6vw', lg:35}, width:{xs:'22vw', lg:120}, borderRadius:{xs:'1vw', lg:2}, fontSize:{xs:'2.8vw', lg:"14px"}, "&:hover":{backgroundColor:"#012d6e"}}} onClick={(event) => {event.stopPropagation(); if(auth.currentUser === null){navigateTo("/ConnexionInscription")} else{onClickContactSupplier()}}}>
            Contacter
          </Button>}
          <Stack direction="row" spacing={0}>
            {auth.currentUser !== null && auth.currentUser?.uid === productInfos.supplierId &&
              <>
                <IconButton size="small" aria-label="editer" onClick={(event) => {event.stopPropagation(); /*onClickEdit()*/ navigateTo("/EditerProduit/" + productInfos.productId, {state:productInfos})}}>
                  <EditIcon sx={{width:{xs:'3.5vw', lg:25}}}/>
                </IconButton>
                <IconButton size="small" aria-label="supprimer" onClick={(event) => {event.stopPropagation(); onClickDelete()}}>
                  <DeleteIcon sx={{width:{xs:'3.5vw', lg:25}}}/>
                </IconButton>
              </>
            }
          </Stack>
        </CardActions>
      </Card>
    </Grow>
  );
}

export default memo(ProductCard);
