import './App.css';
import NavBar from './Components/NavBar';
import Router from './Components/Router';
import { useState } from 'react';
import { UserInfosContext, MessageSentSnackbarContext, ProductDeletedSnackbarContext, ProfilUpdatedSnackbarContext, ProductUpdatedSnackbarContext, SuccessAccountCreationSnackbarContext, ProductAddedSnackbarContext, SuccessNewsLetterSubscriptionSnackbarContext, ResetPasswordEmailSentSnackbarContext, AccountDeletedSnackbarContext, EmailChangedSnackbarContext, PasswordChangedSnackbarContext } from './Components/Contexts';
import LFSnackBar from './Components/LeoFarataFrameWorks/LFSnackBar';

function App() {

  const [userInfos, setUserInfos] = useState({});
  const [messageSentSnackbarOpened, setMessageSentSnackbarOpened] = useState(false);
  const [productDeletedSnackbarOpened, setProductDeletedSnackbarOpened] = useState(false);
  const [profilUpdatedSnackbarOpened, setProfilUpdatedSnackbarOpened] = useState(false);
  const [productUpdatedSnackbarOpened, setProductUpdatedSnackbarOpened] = useState(false);
  const [successAccountCreationSnackbarOpened, setSuccessAccountCreationSnackbarOpened] = useState(false);
  const [productAddedSnackbarOpened, setProductAddedSnackbarOpened] = useState(false);
  const [successNewsLetterSubscriptionSnackbarOpened, setSuccessNewsLetterSubscriptionSnackbarOpened] = useState(false);
  const [resetPasswordEmailSentSnackbarOpened, setResetPasswordEmailSentSnackbarOpened] = useState(false);
  const [accountDeletedSnackbarOpened, setAccountDeletedSnackbarOpened] = useState(false);
  const [emailChangedSnackbarOpened, setEmailChangedSnackbarOpened] = useState(false);
  const [passwordChangedSnackbarOpened, setPasswordChangedSnackbarOpened] = useState(false);

  return (
    <UserInfosContext.Provider value={[userInfos, setUserInfos]}>
      <MessageSentSnackbarContext.Provider value={[messageSentSnackbarOpened, setMessageSentSnackbarOpened]}>
        <ProductDeletedSnackbarContext.Provider value={[productDeletedSnackbarOpened, setProductDeletedSnackbarOpened]}>
          <ProfilUpdatedSnackbarContext.Provider value={[profilUpdatedSnackbarOpened, setProfilUpdatedSnackbarOpened]}>
            <ProductUpdatedSnackbarContext.Provider value={[productUpdatedSnackbarOpened, setProductUpdatedSnackbarOpened]}>
              <SuccessAccountCreationSnackbarContext.Provider value={[successAccountCreationSnackbarOpened, setSuccessAccountCreationSnackbarOpened]}>
                <ProductAddedSnackbarContext.Provider value={[productAddedSnackbarOpened, setProductAddedSnackbarOpened]}>
                  <SuccessNewsLetterSubscriptionSnackbarContext.Provider value={[successNewsLetterSubscriptionSnackbarOpened, setSuccessNewsLetterSubscriptionSnackbarOpened]}>
                   <ResetPasswordEmailSentSnackbarContext.Provider value={[resetPasswordEmailSentSnackbarOpened, setResetPasswordEmailSentSnackbarOpened]}>
                    <AccountDeletedSnackbarContext.Provider value={[accountDeletedSnackbarOpened, setAccountDeletedSnackbarOpened]}>
                     <EmailChangedSnackbarContext.Provider value={[emailChangedSnackbarOpened, setEmailChangedSnackbarOpened]}>
                      <PasswordChangedSnackbarContext.Provider value={[passwordChangedSnackbarOpened, setPasswordChangedSnackbarOpened]}>

                      <>
                        <div className="container">
                          <NavBar/>
                          <Router/>
                        </div>
                        <LFSnackBar opened={messageSentSnackbarOpened} message="Votre message a été envoyé avec succès !" onClose={() => setMessageSentSnackbarOpened(messageSentSnackbarOpened => false)}/>
                        <LFSnackBar opened={productDeletedSnackbarOpened} message="Le produit a été supprimé avec succès !" onClose={() => setProductDeletedSnackbarOpened(productDeletedSnackbarOpened => false)}/>
                        <LFSnackBar opened={profilUpdatedSnackbarOpened} message="Votre profil a été mis à jour avec succès !" onClose={() => setProfilUpdatedSnackbarOpened(profilUpdatedSnackbarOpened => false)}/>
                        <LFSnackBar opened={productUpdatedSnackbarOpened} message="Le produit a été mis à jour avec succès !" onClose={() => setProductUpdatedSnackbarOpened(productUpdatedSnackbarOpened => false)}/>
                        <LFSnackBar opened={successAccountCreationSnackbarOpened} message="Le produit a été mis à jour avec succès !" onClose={() => setSuccessAccountCreationSnackbarOpened(successAccountCreationSnackbarOpened => false)}/>
                        <LFSnackBar opened={productAddedSnackbarOpened} message="Le produit a été ajouté avec succès !" onClose={() => setProductAddedSnackbarOpened(productAddedSnackbarOpened => false)}/>
                        <LFSnackBar opened={successNewsLetterSubscriptionSnackbarOpened} message="Vous vous êtes abonné(e) avec succès à la newsletter !" onClose={() => setSuccessNewsLetterSubscriptionSnackbarOpened(successNewsLetterSubscriptionSnackbarOpened => false)}/>
                        <LFSnackBar opened={resetPasswordEmailSentSnackbarOpened} message="Un email de réinitialisation de mot de passe vous a été envoyé !" onClose={() => setResetPasswordEmailSentSnackbarOpened(resetPasswordEmailSentSnackbarOpened => false)}/>
                        <LFSnackBar opened={accountDeletedSnackbarOpened} message="Votre compte a été supprimé !" onClose={() => setAccountDeletedSnackbarOpened(accountDeletedSnackbarOpened => false)}/>
                        <LFSnackBar opened={emailChangedSnackbarOpened} message="Votre email a été mis à jour avec succès !" onClose={() => setEmailChangedSnackbarOpened(emailChangedSnackbarOpened => false)}/>
                        <LFSnackBar opened={passwordChangedSnackbarOpened} message="Votre mot de passe a été mis à jour avec succès !" onClose={() => setPasswordChangedSnackbarOpened(passwordChangedSnackbarOpened => false)}/>
                      </>

                      </PasswordChangedSnackbarContext.Provider>
                     </EmailChangedSnackbarContext.Provider>
                    </AccountDeletedSnackbarContext.Provider>
                   </ResetPasswordEmailSentSnackbarContext.Provider>
                  </SuccessNewsLetterSubscriptionSnackbarContext.Provider>
                </ProductAddedSnackbarContext.Provider>
              </SuccessAccountCreationSnackbarContext.Provider>
            </ProductUpdatedSnackbarContext.Provider>
          </ProfilUpdatedSnackbarContext.Provider>
        </ProductDeletedSnackbarContext.Provider>
      </MessageSentSnackbarContext.Provider>
    </UserInfosContext.Provider>
  );
}

export default App;
