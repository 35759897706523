import {Routes, Route} from 'react-router-dom';
import HomeView from './View/HomeView';
//import ProjectsView from './View/ProjectsView';
//import DemosView from './View/DemosView';
//import AnimationsView from './View/AnimationsView';
//import ArtworksView from './View/ArtworksView';
import ProductsView from './View/ProductsView';
import SuppliersView from './View/SuppliersView';
import AboutView from './View/AboutView';
import AddProductView from './View/AddProductView';
import MyProductsView from './View/MyProductsView';
import BecomeSupplierView from './View/BecomeSupplierView';
import LoginSignupView from './View/LoginSignupView';
import ProfilView from './View/ProfilView';
import ProductDetailsView from './View/ProductDetailsView';
import SupplierDetailsView from './View/SupplierDetailsView';
import EditProductView from './View/EditProductView';

import FrameworksView from './View/FrameworksView';
import DocumentationsView from './View/DocumentationsView';
import ContactView from './View/ContactView';
import LinksView from './View/LinksView';
import FrameworksWelcomeView from './View/SideBar_Routes_Elements/FrameworksWelcomeView';
import DocumentationsWelcomeView from './View/SideBar_Routes_Elements/DocumentationsWelcomeView';
import SideBarRoutesRenderer from './View/SideBar_Routes_Elements/SideBarRoutesRenderer';
//import FrameworksData from './JSON/FrameworksData';
//import DocumentationsData from './JSON/DocumentationsData';
//import * as FrameworksViewsContentsData from './JSON/SideBar_Routes_Elements_JSON_Data/Frameworks_Views_JSON_Data';
//import * as DocumentationsViewsContentsData from './JSON/SideBar_Routes_Elements_JSON_Data/Documentations_Views_JSON_Data';

import BSDataView from './View/LinksPages/BSDataView';
import BSPoliciesView from './View/LinksPages/BSPoliciesView';
import BSTermsView from './View/LinksPages/BSTermsView';
import DeveloperPagesView from './View/LinksPages/DeveloperPagesView';
import LFSCDataView from './View/LinksPages/LFSCDataView';
import LFSCPoliciesView from './View/LinksPages/LFSCPoliciesView';
import LFSCTermsView from './View/LinksPages/LFSCTermsView';
import OCPoliciesView from './View/LinksPages/UnityApps/OCPoliciesView';
import NCPoliciesView from './View/LinksPages/UnityApps/NCPoliciesView';
import FJPoliciesView from './View/LinksPages/UnityApps/FJPoliciesView';
import OCDeleteView from './View/LinksPages/UnityApps/OCDeleteView';
import NCDeleteView from './View/LinksPages/UnityApps/NCDeleteView';
import FJDeleteView from './View/LinksPages/UnityApps/FJDeleteView';
import GXPoliciesView from './View/LinksPages/UnityApps/GXPoliciesView';
import SDPoliciesView from './View/LinksPages/UnityApps/SDPoliciesView';
import OWPPoliciesView from './View/LinksPages/UnityApps/OWPPoliciesView';

//import * as FrameworksViews from './View/SideBar_Routes_Elements/Frameworks_Views';
//import * as DocumentationsViews from './View/SideBar_Routes_Elements/Documentations_Views';

export default function Router(){
  return(
    <Routes>
     <Route exact path='/' element={<HomeView/>}/>
     <Route path='/Accueil' element={<HomeView/>}/>
     <Route path='/Produits' element={<ProductsView/>}/>
     <Route path='/Fournisseurs/*' element={<SuppliersView/>}/>
     <Route path='/Apropos' element={<AboutView/>}/>
     <Route path='/AjouterUnProduit' element={<AddProductView/>}/>
     <Route path='/MesProduits' element={<MyProductsView/>}/>
     <Route path='/DevenirFournisseur' element={<BecomeSupplierView/>}/>
     <Route path='/ConnexionInscription' element={<LoginSignupView/>}/>
     <Route path='/Profil' element={<ProfilView/>}/>
     <Route path='/FicheProduit/:productId' element={<ProductDetailsView/>}/>
     <Route path='/FicheFournisseur/:supplierId' element={<SupplierDetailsView/>}/>
     <Route path='/EditerProduit/:productId' element={<EditProductView/>}/>

     {/*<Route path="/Frameworks/*" element={<FrameworksView/>}>
       <Route index element={<FrameworksWelcomeView/>}/>
       <Route exact path='ReactNative/AlertView' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.AlertView}/>}/>
       <Route exact path='ReactNative/AudioWavesAnimation' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.AudioWavesAnimations}/>}/>
       <Route exact path='ReactNative/BottomSheetView' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.BottomSheetView}/>}/>
       <Route exact path='ReactNative/LFButton' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.LFButton}/>}/>
       <Route exact path='ReactNative/SingleStoryView' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.SingleStoryView}/>}/>
       <Route exact path='ReactNative/StoriesView' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.StoriesView}/>}/>

       <Route exact path='React/AnimationCard' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.AnimationCard}/>}/>
       <Route exact path='React/ArtworkCard' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.ArtworkCard}/>}/>
       <Route exact path='React/CodingCard' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.CodingCard}/>}/>
       <Route exact path='React/DemonstrationCard' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.DemonstrationCard}/>}/>
       <Route exact path='React/LFModalView' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.LFModalView}/>}/>
       <Route exact path='React/LFTable' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.LFTable}/>}/>
       <Route exact path='React/PolygonCard' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.PolygonCard}/>}/>
       <Route exact path='React/PresentationCard' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.PresentationCard}/>}/>
       <Route exact path='React/SideBarComponents' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.SideBarComponents}/>}/>
       <Route exact path='React/SideBarRoutesRenderer' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.SideBarRoutesRenderer}/>}/>

       <Route exact path='Unity/BoutonPoussoir3D' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.BoutonPoussoir3D}/>}/>
       <Route exact path='Unity/ExecuteActionQuest' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.ExecuteActionQuest}/>}/>
       <Route exact path='Unity/ExecuteKeyQuest' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.ExecuteKeyQuest}/>}/>
       <Route exact path='Unity/InterrupteurElastique' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.InterrupteurElastique}/>}/>
       <Route exact path='Unity/InterrupteurMultiPosition' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.InterrupteurMultiPosition}/>}/>
       <Route exact path='Unity/InterrupteurZPT' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.InterrupteurZPT}/>}/>
       <Route exact path='Unity/ManipulateurTF' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.ManipulateurTF}/>}/>
       <Route exact path='Unity/MoveToPositionQuest' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.MoveToPositionQuest}/>}/>
       <Route exact path='Unity/OffVoiceSpeech' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.OffVoiceSpeech}/>}/>
       <Route exact path='Unity/RadialMenu' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.RadialMenu}/>}/>
       <Route exact path='Unity/RadialSlider' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.RadialSlider}/>}/>
       <Route exact path='Unity/SNCFTutorialGraphEditor' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.SNCFTutorialGraphEditor}/>}/>
       <Route exact path='Unity/ToggleBoutonPoussoir3D' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.ToggleBoutonPoussoir3D}/>}/>
       <Route exact path='Unity/Transformer' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.Transformer}/>}/>
       <Route exact path='Unity/TutorialManager' element={<SideBarRoutesRenderer viewContentData={FrameworksViewsContentsData.TutorialManager}/>}/>
     </Route>*/}

     <Route path='/Contact' element={<ContactView/>}/>
     {/*<Route path='/Liens' element={<LinksView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteLeoFarataSocialClub' element={<LFSCPoliciesView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteBeSafe' element={<BSPoliciesView/>}/>
     <Route exact path='/Liens/ConditionsUtilisationLeoFarataSocialClub' element={<LFSCTermsView/>}/>
     <Route exact path='/Liens/PagesDeveloppeur' element={<DeveloperPagesView/>}/>
     <Route exact path='/Liens/ConditionsUtilisationBeSafe' element={<BSTermsView/>}/>
     <Route exact path='/Liens/DonneesPersonnellesLeoFarataSocialClub' element={<LFSCDataView/>}/>
     <Route exact path='/Liens/DonneesPersonnellesBeSafe' element={<BSDataView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteOsayCash' element={<OCPoliciesView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteNiamCity' element={<NCPoliciesView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteFighterJet' element={<FJPoliciesView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteGravityXcape' element={<GXPoliciesView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteSuperDodger' element={<SDPoliciesView/>}/>
     <Route exact path='/Liens/PolitiqueDeConfidentialiteOpenWorldProto' element={<OWPPoliciesView/>}/>
     <Route exact path='/Liens/SecuriteDesDonneesOsayCash' element={<OCDeleteView/>}/>
     <Route exact path='/Liens/SecuriteDesDonneesNiamCity' element={<NCDeleteView/>}/>
     <Route exact path='/Liens/SecuriteDesDonneesFighterJet' element={<FJDeleteView/>}/>*/}

     {/*<Route path='/Documentations/*' element={<DocumentationsView/>}>
        <Route index element={<DocumentationsWelcomeView/>}/>
        <Route exact path='DocsProjetsReact/LeoFarataTechnologies/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationLeoFarataTechnologies}/>}/>
        <Route exact path='DocsProjetsReact/LeoFarataTechnologies/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsReact/LeoFarataTechnologies/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsReactNative/BeSafe/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationBeSafe}/>}/>
        <Route exact path='DocsProjetsReactNative/BeSafe/Fonctionnalitees' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.FonctionnaliteesBeSafe}/>}/>
        <Route exact path='DocsProjetsReactNative/BeSafe/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsReactNative/BeSafe/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsReactNative/LeoFarataSocialClub/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationLeoFarataSocialClub}/>}/>
        <Route exact path='DocsProjetsReactNative/LeoFarataSocialClub/Fonctionnalitees' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.FonctionnaliteesLeoFarataSocialClub}/>}/>
        <Route exact path='DocsProjetsReactNative/LeoFarataSocialClub/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsReactNative/LeoFarataSocialClub/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>


        <Route exact path='DocsProjetsUnity/GravityXcape/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationGravityXcape}/>}/>
        <Route exact path='DocsProjetsUnity/GravityXcape/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/GravityXcape/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/MetaSpace/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationMetaSpace}/>}/>
        <Route exact path='DocsProjetsUnity/MetaSpace/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/MetaSpace/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/NiamCity/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationNiamCity}/>}/>
        <Route exact path='DocsProjetsUnity/NiamCity/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/NiamCity/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/NoShakingThrone/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationNoShakingThrone}/>}/>
        <Route exact path='DocsProjetsUnity/NoShakingThrone/MecanismesDeJeu' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.FonctionnaliteesNoShakingThrone}/>}/>
        <Route exact path='DocsProjetsUnity/NoShakingThrone/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/NoShakingThrone/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/OsayCash/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationOsayCash}/>}/>
        <Route exact path='DocsProjetsUnity/OsayCash/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/OsayCash/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/SNCFSimulateursCF/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationSNCFSimulateursCF}/>}/>
        <Route exact path='DocsProjetsUnity/SNCFSimulateursCF/FonctionnaliteesEtMecanismes' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.FonctionnaliteesSNCFSimulateursCF}/>}/>
        <Route exact path='DocsProjetsUnity/SNCFSimulateursCF/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/SNCFSimulateursCF/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/SuperDodger/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationSuperDodger}/>}/>
        <Route exact path='DocsProjetsUnity/SuperDodger/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/SuperDodger/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>

        <Route exact path='DocsProjetsUnity/TurretDestroyer/Presentation' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.PresentationTurretDestroyer}/>}/>
        <Route exact path='DocsProjetsUnity/TurretDestroyer/Architecture' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
        <Route exact path='DocsProjetsUnity/TurretDestroyer/CodeSource' element={<SideBarRoutesRenderer viewContentData={DocumentationsViewsContentsData.NoAccessDocumentationAdmin}/>}/>
     </Route>*/}

    </Routes>
  )
}
