import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function LFWarningAlert({opened, text, confirmButtonName, handleConfirm, handleCancel}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={opened}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
        sx={{"& .MuiDialog-paper":{borderRadius:2, overflow:'hidden', pr:1.5, py:1.5}}}
      >
        <DialogTitle id="responsive-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center">
            <WarningRoundedIcon sx={{width:30, height:30, color:'#bd0000'}}/>
            <span>Attention !</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button endIcon={<DeleteRoundedIcon/>} autoFocus onClick={handleConfirm} sx={{color:'white', backgroundColor:'#bd0000', borderRadius:2, "&:hover":{backgroundColor:'#820000'}}}>
            {confirmButtonName}
          </Button>
          <Button endIcon={<CancelRoundedIcon/>} onClick={handleCancel} autoFocus sx={{color:'white', backgroundColor:'#0053CF', borderRadius:2, "&:hover":{backgroundColor:'#012d6e'}}}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
