import {memo} from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

function SearchForm({width, height, buttonWidth, fontSize, marginTop, defaultValue, placeholder, inputType, inputName, inputId, buttonText, buttonTextColor, buttonColor, iconColor, buttonTextHoverColor, buttonHoverColor, iconHoverColor, onSubmit, required}) {
  return (
    <Paper
      component="form"
      sx={{ p: '2px', display: 'flex', alignItems: 'center', width: width ?? 400, height: height ?? undefined, mt: marginTop ?? undefined }}
      onSubmit={onSubmit}
    >
      {/*<IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>*/}
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: fontSize ?? undefined }}
        placeholder={placeholder ?? "Placeholder"}
        inputProps={{ 'aria-label': placeholder ?? "Placeholder" }}
        type={inputType ?? "search"}
        name={inputName ?? "search"}
        id={inputId ?? "search-bar"}
        defaultValue={defaultValue ?? undefined}
        required={required ?? true}
      />
      <Button type="submit" sx={{ p: '10px', height: height ?? undefined, width: buttonWidth ?? undefined, textTransform: 'none', fontWeight: 'bold', color: buttonTextColor ?? undefined, backgroundColor: buttonColor ?? undefined, "&:hover":{ color: buttonTextHoverColor ?? undefined, backgroundColor: buttonHoverColor ?? undefined } }} aria-label="search">
        {buttonText ?? <SearchIcon sx={{color: iconColor ?? undefined, width: buttonWidth ?? undefined, "&:hover":{color: iconHoverColor ?? undefined}}}/>}
      </Button>
      {/*<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton>*/}
    </Paper>
  );
}

export default memo(SearchForm);
