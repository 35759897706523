import { createContext } from 'react';

export const UserInfosContext = createContext();
export const MessageSentSnackbarContext = createContext();
export const ProductDeletedSnackbarContext = createContext();
export const ProfilUpdatedSnackbarContext = createContext();
export const ProductUpdatedSnackbarContext = createContext();
export const SuccessAccountCreationSnackbarContext = createContext();
export const ProductAddedSnackbarContext = createContext();
export const SuccessNewsLetterSubscriptionSnackbarContext = createContext();
export const ResetPasswordEmailSentSnackbarContext = createContext();
export const AccountDeletedSnackbarContext = createContext();
export const EmailChangedSnackbarContext = createContext();
export const PasswordChangedSnackbarContext = createContext();
