import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ChatIcon from '@mui/icons-material/Chat';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TextField from '@mui/material/TextField';
import {useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import { MessageSentSnackbarContext } from '../Contexts';
import axios from 'axios';
import {auth, db, doc, getDoc} from '../Firebase/firebase';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function LFContactSupplierDialogForm({opened, handleClose, productQuantity, targetProduct}) {

  const location = useLocation();

  const [loading, setLoading] = useState(false);
  //setLoading(loading => true);

  let userInfosString = sessionStorage.getItem("@userInfos");
  let userInfos = null;
  if(userInfosString){
    userInfos = JSON.parse(userInfosString);
  }

  const [formData, setFormData] = useState({
    email: userInfos?.email,
    //phone: userInfos?.phone,
    firstname: userInfos?.firstname,
    name: userInfos?.name,
    subject: `Contact au sujet du produit : ${targetProduct?.productId} (${targetProduct?.productName})`,
    message: `Bonjour,\n\nJe vous contacte concernant votre produit et souhaiterais obtenir plus d'informations à son sujet. Pouvez-vous me fournir des détails supplémentaires, notamment sur les caractéristiques techniques, la disponibilité en stock et les options de livraison ?\n\nJe vous remercie par avance pour votre retour.\n\nQuantité de produit souhaitée : ${productQuantity ?? 1}\n\nCordialement\n\n${userInfos?.firstname} ${userInfos?.name}`,
    supplierEmail: "",
    product: targetProduct,
    productLink: `${window.location.hostname}/Produits/${targetProduct?.productId}`
  });

  const [messageSentSnackbarOpened, setMessageSentSnackbarOpened] = useContext(MessageSentSnackbarContext);

  // Contacter le fournisseur ===============
  const contactSupplier = async () => {
    setLoading(loading => true);
    await getDoc(doc(db, "users", targetProduct.supplierId))
    .then(async (document) => {
      const supplierInfos = document.data();
      //setFormData({...formData, ["supplierEmail"]: supplierInfos.email});
      formData.supplierEmail = supplierInfos.email
      return await axios.post("https://aqso-server-m7nl.onrender.com/MailSender/ContactSupplier", formData);
    })
    .then(() => {
      setLoading(loading => false);
      setMessageSentSnackbarOpened(messageSentSnackbarOpened => true);
      handleClose();
    })
    .catch(() => {setLoading(loading => false);});
  };

  useEffect(() => {
    const dataInit = {
      email: userInfos?.email,
      //phone: userInfos?.phone,
      firstname: userInfos?.firstname,
      name: userInfos?.name,
      subject: `Contact au sujet du produit : ${targetProduct?.productId} (${targetProduct?.productName})`,
      message: `Bonjour,\n\nJe vous contacte concernant votre produit et souhaiterais obtenir plus d'informations à son sujet. Pouvez-vous me fournir des détails supplémentaires, notamment sur les caractéristiques techniques, la disponibilité en stock et les options de livraison ?\n\nJe vous remercie par avance pour votre retour.\n\nQuantité de produit souhaitée : ${productQuantity ?? 1}\n\nCordialement\n\n${userInfos?.firstname} ${userInfos?.name}`,
      supplierEmail: "",
      product: targetProduct,
      productLink: `${window.location.hostname}/Produits/${targetProduct?.productId}`
    };
    setFormData(formData => dataInit);
  }, [targetProduct]);

  return (
    </*form onSubmit={(event) => {event.preventDefault(); handleSend(event)}}*/>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        sx={{"& .MuiDialog-paper":{borderRadius:2, overflow:'hidden'}}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center">
            <ChatIcon sx={{width:30, height:30, color:'#0053CF'}}/>
            <span>Contacter le fournisseur</span>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack sx={{width:'100%'}} spacing={2}>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField disabled={userInfos === null ? false : true} defaultValue={userInfos?.email} onChange={(event) => setFormData({...formData, ["email"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type='email' id="email" name="email" label="Email" placeholder="Email" required/>
            </Stack>

            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField disabled={userInfos === null ? false : true} defaultValue={userInfos?.firstname} onChange={(event) => setFormData({...formData, ["firstname"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="firstname" name="firstname" label="Prénom" placeholder="Prénom" required/>
              <TextField disabled={userInfos === null ? false : true} defaultValue={userInfos?.name} onChange={(event) => setFormData({...formData, ["name"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="name" name="name" label="Nom" placeholder="Nom" required/>
            </Stack>

            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField defaultValue={`Contact au sujet du produit : ${targetProduct?.productId} (${targetProduct?.productName})`} onChange={(event) => setFormData({...formData, ["subject"]: event.target.value})} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="subject" name="subject" label="Sujet" placeholder="Sujet" required/>
            </Stack>

            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
             <TextField fullWidth defaultValue={`Bonjour,\n\nJe vous contacte concernant votre produit et souhaiterais obtenir plus d'informations à son sujet. Pouvez-vous me fournir des détails supplémentaires, notamment sur les caractéristiques techniques, la disponibilité en stock et les options de livraison ?\n\nJe vous remercie par avance pour votre retour.\n\nQuantité de produit souhaitée : ${productQuantity ?? 1}\n\nCordialement\n\n${userInfos?.firstname} ${userInfos?.name}`}
              onChange={(event) => setFormData({...formData, ["message"]: event.target.value})}
              multiline
              rows={4}
              maxRows={4}
              sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}}
              error={false}
              id="message"
              name="message"
              label="Message"
              placeholder="Message"
              required/>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            endIcon={<SendRoundedIcon/>}
            sx={{color:'white', backgroundColor:'#168000', borderRadius:2, boxShadow:'none', "&:hover":{backgroundColor:'#0c4500'}}}
            loadingIndicator={<CircularProgress color="inherit" size={22}/>}
            loading={loading}
            /*loadingPosition="end"*/
            variant="contained"
            type="submit"
            /*autoFocus*/
            onClick={contactSupplier}
          >
            Envoyer
          </LoadingButton>
          <Button endIcon={<CancelRoundedIcon/>} /*autoFocus*/ onClick={handleClose} sx={{color:'white', backgroundColor:'#0053CF', borderRadius:2, "&:hover":{backgroundColor:'#012d6e'}}}>
            Annuler
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ /*form*/>
  );
}
