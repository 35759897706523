//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/ContactPage.css';
//import SideBar from '../SideBar';
import Footer from '../Footer';
import contact_gif from '../../assets/logo_aqso.png';
//import videoWind from "../../assets/wind.mp4";
import icone_email from '../../assets/icone_email.png';
import icone_telephone from '../../assets/icone_telephone.png';
import icone_instagram from '../../assets/icone_instagram.png';
//import ActivityIndicator from '../LeoFarataFrameWorks/ActivityIndicator';
//import {useState} from 'react';
//import {useState, /*useEffect,*/ useRef} from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: '300px',
  width: '100%',
};

const center = {
  lat: 14.716677,
  lng: -17.467686,
};

export default function ContactView(){

  //const [artworkModalVisible, setArtworkModalVisible] = useState(false);
  //const selectedItem = useRef('');
  //const [loading, setLoading] = useState(true);

  return (
    <div className='page-container'>

      <div className='page-body'>
        <div className='page-content'>
          <div className="contact-container">

          <div className="contact-form">
           <h2>Contactez-nous</h2>
           <form>
             <label htmlFor="firstName">Prénom</label>
             <input type="text" id="firstName" name="firstName" placeholder="Veuillez entrer votre prénom..." />

             <label htmlFor="email">Email</label>
             <input type="email" id="email" name="email" placeholder="Veuillez entrer votre email..." />

             <label htmlFor="phoneNumber">Numéro de téléphone</label>
             <input type="text" id="phoneNumber" name="phoneNumber" placeholder="Veuillez entrer votre numéro de téléphone..." />

             <label htmlFor="message">Quel est votre message ?</label>
             <textarea id="message" name="message" placeholder="Veuillez entrer votre message..."></textarea>

             <button type="submit">Envoyer</button>
           </form>
         </div>
         <div className="contact-info">
           <h3>Contactez-nous à</h3>
           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Felis diam lectus sapien.</p>
           <div className="social-icons">
             <a href="#"><img src="twitter_icon_url" alt="Twitter" /></a>
             <a href="#"><img src="facebook_icon_url" alt="Facebook" /></a>
             <a href="#"><img src="google_icon_url" alt="Google" /></a>
             <a href="#"><img src="instagram_icon_url" alt="Instagram" /></a>
           </div>
          { /*<div className="map">
             <LoadScript googleMapsApiKey="AIzaSyAMaQggcl6K5XSfvQDrV8GenWvg3Bv9Eeo">
               <GoogleMap
                 mapContainerStyle={mapContainerStyle}
                 center={center}
                 zoom={13}
               >
                 <Marker position={center} />
               </GoogleMap>
             </LoadScript>
           </div>*/}
         </div>

          </div>
         </div>
         <Footer/>
      </div>

    </div>
  )
}
