import '../../Styles/Frameworks_Styles/SupplierCard.css';
import ActivityIndicator from './ActivityIndicator';
import {useState, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIcon from '@mui/icons-material/Chat';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import Grow from '@mui/material/Grow';
import {auth} from '../Firebase/firebase';

function SupplierCard({index, supplierInfos, onClick}){
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(true);
  return(
    <Grow in={true}>
      <Card sx={{ width:{xs:'41.8vw', lg:'250px'}, /*height:{xs:'55vw', lg:'300px'},*/ boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", borderRadius:2, border:"solid white 3px", "&:hover":{cursor:"pointer", border:"solid #0053CF 3px", boxShadow:"#0053CF 0px 0px 30px 3.75px"} }} onClick={() => {!auth?.currentUser ? navigateTo("/ConnexionInscription") : auth.currentUser.uid !== supplierInfos.userId ? navigateTo("/FicheFournisseur/" + supplierInfos.userId, {state:supplierInfos}) : navigateTo("/Profil", {state:supplierInfos})}}>
        <CardContent sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:{xs:"center", lg:"stretch"}, gap:{xs:0.5, lg:1}, height:40}}>
          <Stack direction={{xs:"column", lg:"row"}} justifyContent={{xs:"center", lg:"space-between"}} alignItems="center" sx={{gap:{xs:0.5, lg:0}}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
              <Typography gutterBottom variant="h7" component="div" sx={{fontSize:{xs:'3.5vw', lg:"16px"}, paddingLeft:{xs:'0px', lg:'4px'}, maxWidth:'100px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', textAlign:{xs:'center', lg:'left'}}}>
                {supplierInfos.username}
              </Typography>
              {supplierInfos?.isVerified===true && <img style={{width:15, height:15}} src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Ficone_certifi%C3%A9.png?alt=media&token=90b28937-f852-45cd-bb12-d427ad354f2a" alt="Verified Icon"/>}
            </Stack>
            <Rating name="supplierInfos.rating" size="small" value={supplierInfos.rating} readOnly/>
          </Stack>
          <Stack direction="row" spacing={0.3} sx={{display:'flex', justifyContent:{xs:'center', lg:'flex-start'}, alignItems:'center'}}>
            <LocationOnIcon sx={{width:{xs:'3vw', lg:15}, height:{xs:'3vw', lg:15}}}/>
            <Typography variant="body2" color="#FF6A00" sx={{fontSize:{xs:'2.9vw', lg:13}, color:'black', maxWidth:'100px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
              {supplierInfos.country}
            </Typography>
          </Stack>
        </CardContent>
        <CardMedia
          component="img"
          sx={{height:{xs:'41.8vw', lg:'250px'}}}
          image={supplierInfos.profilPicture ?? "https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/users%2Fphoto_de_profil_par_defaut.jpg?alt=media&token=f142bd79-0fe2-4f0c-a16c-bae4a84a6932"}
          alt="Profil Image"
        />
      </Card>
    </Grow>
  );
}

export default memo(SupplierCard);
