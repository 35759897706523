import logo from '../assets/logo_aqso.png';
import fermer_icone from '../assets/icone_fermer.png';
import menu_icone from '../assets/icone_menu.png';
import {NavLink, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LFDialog from './LeoFarataFrameWorks/LFDialog';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import StoreIcon from '@mui/icons-material/Store';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import DevicesIcon from '@mui/icons-material/Devices';
import {auth, onAuthStateChanged, db, doc, getDoc} from './Firebase/firebase';

export default function NavBar(){

  const [dialogData, setDialogData] = useState({
    opened: false,
    text: ''
  });

  const [mobileScreenIcone, setMobileScreenIcone] = useState(menu_icone);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfos, setUserInfos] = useState({});

  const navigateTo = useNavigate();

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 13,
    height: 13,
    color:'#0053CF',
    border: `2px solid ${theme.palette.background.paper}`
  }));

  const hideSideNavBar = () => {
    document.getElementsByClassName('header-nav-links-container')[0].classList.remove('show-nav-menu');
    document.getElementsByClassName('menu-icone')[0].classList.remove('rotate-menu-icone');
    setMobileScreenIcone(mobileScreenIcone => menu_icone);
  }

  const [drawerOpened, setDrawerOpened] = useState(false);

  /*onAuthStateChanged(auth, (user) => {
    if(user){
      if(!loggedIn){
        (async () => {
          await getDoc(doc(db, "users", user.uid))
          .then((document) => {
            const infos = document.data();
            setUserInfos(userInfos => infos);
            console.log(infos);
            sessionStorage.setItem("@userInfos", JSON.stringify(infos));
          })
          .catch((err) => console.log(err))
        })()
      }
      setLoggedIn(loggedIn => true);
    }
    else{
     setLoggedIn(loggedIn => false);
    }
  });*/

  useEffect(() => {
    //console.log("magie");
    const userInfosSessionStorage = sessionStorage.getItem("@userInfos");
    if(userInfosSessionStorage !== null && userInfosSessionStorage.length !== 0){
      setUserInfos(userInfos => JSON.parse(userInfosSessionStorage));
      setLoggedIn(loggedIn => true);
    }
    else{
     setLoggedIn(loggedIn => false);
    }
  }, [sessionStorage.getItem("@userInfos")])

  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;

  const handleClick = (event) => {
    if(loggedIn) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await auth.signOut()
    .then(() => {sessionStorage.setItem("@userInfos", ""); setLoggedIn(loggedIn => false); navigateTo("/ConnexionInscription")})
    .catch((err) => console.log(err));
  };


  return (
    <div className="App">
      <header className="App-header">
        <Container maxWidth="100%" sx={{display:'flex', direction:'row', justifyContent:'space-between', alignItems:'center', boxSizing:'border-box'}}>
          <NavLink className='header-nav-logo' to='./'><img src={logo} className='logo' alt='logo'/></NavLink>

          <nav className="header-nav-links-container">

             <Box sx={{ display: 'flex', justifyContent:'flex-end', alignItems: 'center', textAlign: 'center'}}>
                <Stack direction="row" spacing={0.5}>
                  <Button startIcon={<HomeOutlinedIcon/>} size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/Accueil'><span>Accueil</span></Button>
                  {loggedIn && <>
                    <Button startIcon={<ShoppingBasketOutlinedIcon/>} size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/Produits'><span>Produits</span></Button>
                    <Button startIcon={<StoreOutlinedIcon/>} size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/Fournisseurs'><span>Fournisseurs</span></Button>
                  </>}
                  <Button startIcon={<InfoOutlinedIcon/>} size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/Apropos'><span>À propos</span></Button>
                  {!loggedIn && <>
                    <Button startIcon={<AddBusinessIcon/>} size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/DevenirFournisseur'><span>Devenir fournisseur</span></Button>
                    <Button size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/ConnexionInscription'><span>Connexion / Inscription</span></Button>
                  </>}
                  {/*<Button startIcon={<PermContactCalendarOutlinedIcon/>} size='medium' sx={{textTransform: 'capitalize', fontWeight:'bold', height:30, color:'black', borderRadius:2, "&:hover":{color:"white", backgroundColor:"#0053CF"}, "&.active":{color:"white", backgroundColor:"#FF6A00"}}} component={NavLink} to='/Contact'><span>Contact</span></Button>*/}
                </Stack>

                {loggedIn && <><Stack spacing={0} direction="row">
                  <IconButton size="medium" sx={{ color: 'text.primary' }} onClick={() => setDialogData({opened:true, text:'Cette fonctionnalité est en cours de développement et sera disponible dans les prochaines mises à jour.'})}>
                    <Badge color="primary" badgeContent={0} max={999} showZero sx={{".MuiBadge-colorPrimary":{backgroundColor:'#0053CF'}}}>
                      <NotificationsNoneOutlinedIcon sx={{color:'black'}}/>
                    </Badge>
                  </IconButton>
                  <IconButton size="medium" sx={{ color: 'text.primary' }} onClick={() => setDialogData({opened:true, text:'Cette fonctionnalité est en cours de développement et sera disponible dans les prochaines mises à jour.'})}>
                    <Badge color="primary" badgeContent={0} max={999} showZero sx={{".MuiBadge-colorPrimary":{backgroundColor:'#0053CF'}}}>
                      <EmailOutlinedIcon sx={{color:'black'}}/>
                    </Badge>
                  </IconButton>
                </Stack>

                <Tooltip arrow title={!loggedIn ? "" : "Mon Compte"}>
                <Button
                  onClick={handleClick}
                  component={loggedIn ? null : NavLink}
                  to={loggedIn ? undefined : '/ConnexionInscription'}
                  size="small"
                  sx={{ ml: 2, color:'#FF6A00', borderRadius:6, "&:hover":{backgroundColor:"#edf4ff"}, "&.active":{backgroundColor:"#fff5ed"}}}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >

                  <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
                    <Typography variant="subtitle2" sx={{ textTransform: 'capitalize', color:'black', fontWeight:'bold', /*width:'75px', overflow:'hidden', textOverflow:'ellipsis',*/ whiteSpace:'nowrap'}}>{loggedIn ? userInfos.username : "Connexion / Inscription"}</Typography>
                    <Badge
                      invisible={!userInfos?.isVerified}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Verified Icon" src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Ficone_certifi%C3%A9.png?alt=media&token=90b28937-f852-45cd-bb12-d427ad354f2a" />
                      }
                    >
                      <Avatar sx={{width:35, height:35}} alt="Profil Image" src={userInfos.profilPicture}/>
                    </Badge>
                  </Stack>
                 </Button>
                </Tooltip></>}
              </Box>

             <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  width:300,
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Stack direction="row" spacing={1.25} alignItems="center">
                      <Badge
                        invisible={!userInfos?.isVerified}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <img style={{width:13, height:13, border: `2px solid white`, borderRadius:13, marginRight:15}} alt="Verified Icon" src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Ficone_certifi%C3%A9.png?alt=media&token=90b28937-f852-45cd-bb12-d427ad354f2a" />
                        }
                      >
                        <Avatar alt="Profil Image" src={userInfos.profilPicture}/>
                      </Badge>
                      <Stack spacing={-0}>
                        <Typography variant="h6" sx={{maxWidth:'170px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{userInfos.username}</Typography>
                        <Typography variant="body2" color="text.secondary">{userInfos.accounttype}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Se déconnecter">
                      <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleLogout}>
                        <Logout fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <MenuItem sx={{mt:1.5}} onClick={() => {handleClose(); navigateTo("/Profil")}}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Profil
              </MenuItem>
              {/*<MenuItem onClick={() => {handleClose(); navigateTo("/MesProduits")}}>
                <ListItemIcon>
                  <LocalMallIcon fontSize="small" />
                </ListItemIcon>
                Mes produits
              </MenuItem>
              <MenuItem onClick={() => {handleClose(); navigateTo("/AjouterUnProduit")}}>
                <ListItemIcon>
                  <PlaylistAddCircleIcon fontSize="small" />
                </ListItemIcon>
                Ajouter un produit
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <SpaceDashboardIcon fontSize="small" />
                </ListItemIcon>
                Tableau de bord
              </MenuItem>*/}
              <MenuItem onClick={handleClose, () => {setDialogData({opened:true, text:'Cette fonctionnalité est en cours de développement et sera disponible dans les prochaines mises à jour.'})}}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Paramètres
              </MenuItem>
              <MenuItem onClick={handleClose, handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Se déconnecter
              </MenuItem>
            </Menu>
          </nav>

          <IconButton size="small" sx={{ display:{xs:'flex', md:'none'}, color: 'text.primary' }} onClick={() => setDrawerOpened(drawerOpened => true)}>
            <MenuIcon sx={{color:'black', width:35, height:35}}/>
          </IconButton>

          <Drawer
            anchor="right"
            open={drawerOpened}
            onClose={() => setDrawerOpened(drawerOpened => false)}
          >
            <Box
              sx={{ width:250 }}
              role="presentation"
              onClick={() => setDrawerOpened(drawerOpened => false)}
              onKeyDown={() => setDrawerOpened(drawerOpened => false)}
            >
              {loggedIn && <><CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Stack direction="row" spacing={1.25} alignItems="center">
                      <Badge
                        invisible={!userInfos?.isVerified}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <img style={{width:14, height:14, border: `2px solid white`, borderRadius:13, marginRight:0}} alt="Verified Icon" src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Ficone_certifi%C3%A9.png?alt=media&token=90b28937-f852-45cd-bb12-d427ad354f2a" />
                        }
                      >
                        <Avatar alt="Profil Image" src={userInfos.profilPicture}/>
                      </Badge>
                      <Stack spacing={-0.8}>
                        <Typography variant="h6" sx={{maxWidth:'120px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{userInfos.username}</Typography>
                        <Typography variant="body2" color="text.secondary">{userInfos.accounttype}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Se déconnecter">
                      <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleLogout}>
                        <Logout fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider /></>}

              <List sx={{mb:0, pb:0}}>
                {['Accueil', 'Produits', 'Fournisseurs', 'À propos', 'Notifications', 'Messagerie', 'Devenir fournisseur', 'Connexion / Inscription'].map((text, index) => (
                  <ListItem key={text} disablePadding sx={{display:([1,2,4,5].includes(index) && !loggedIn) ? 'none' : ([6,7].includes(index) && loggedIn) ? 'none' : 'block'}}>
                    <ListItemButton sx={{/*textTransform: 'capitalize',*/ fontWeight:'bold', color:'black', backgroundColor:'#f6f6f6', borderRadius:1, px:1, mx:1, "&:hover":(index !== 4 && index !== 5) ? {color:"white", backgroundColor:"#0053CF", "& .button-icon":{color:'white'}} : undefined, "&.active":(index !== 4 && index !== 5) ? {color:"white", backgroundColor:"#FF6A00", "& .button-icon":{color:'white'}} : undefined}} component={NavLink} to={['/Accueil', '/Produits', '/Fournisseurs', '/Apropos', undefined, undefined, '/DevenirFournisseur', '/ConnexionInscription'][index]} onClick={((index === 4 || index === 5)) ? () => setDialogData({opened:true, text:'Cette fonctionnalité est en cours de développement et sera disponible dans les prochaines mises à jour.'}) : undefined}>
                      <ListItemIcon sx={{minWidth:40}}>
                        {[<HomeIcon className="button-icon"/>, <ShoppingBasketIcon className="button-icon"/>, <StoreIcon className="button-icon"/>, <InfoIcon className="button-icon"/>,
                          <Badge color="primary" badgeContent={0} max={999} showZero sx={{".MuiBadge-colorPrimary":{backgroundColor:'#0053CF'}}}>
                            <NotificationsIcon className="button-icon"/>
                          </Badge>,
                          <Badge color="primary" badgeContent={0} max={999} showZero sx={{".MuiBadge-colorPrimary":{backgroundColor:'#0053CF'}}}>
                            <EmailIcon className="button-icon"/>
                          </Badge>,
                        <AddBusinessIcon className="button-icon"/>, <DevicesIcon className="button-icon"/>][index]}
                      </ListItemIcon>
                      <ListItemText primary={text} sx={{m:0, p:0}}/>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              {loggedIn && <><Divider />
              <List>
                {['Profil', 'Paramètres', 'Se déconnecter'].map((text, index) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton sx={{/*textTransform: 'capitalize',*/ fontWeight:'bold', color:'black', backgroundColor:'#f6f6f6', borderRadius:1, px:1, mx:1}} onClick={[() => navigateTo("/Profil"), () => setDialogData({opened:true, text:'Cette fonctionnalité est en cours de développement et sera disponible dans les prochaines mises à jour.'}), () => handleLogout()][index]}>
                      <ListItemIcon sx={{minWidth:40}}>
                        {[<AccountCircleIcon/>, <Settings/>, <Logout/>][index]}
                      </ListItemIcon>
                      <ListItemText primary={text} sx={{m:0}}/>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List></>}
            </Box>
          </Drawer>


          {/*<div className='menu-icone'>
           <img src={mobileScreenIcone} alt='menu icone' onClick={() => {
             document.getElementsByClassName('header-nav-links-container')[0].classList.toggle('show-nav-menu');
             document.getElementsByClassName('menu-icone')[0].classList.toggle('rotate-menu-icone');
             setMobileScreenIcone(mobileScreenIcone => mobileScreenIcone === menu_icone ? fermer_icone : menu_icone);
           }}/>
          </div>*/}
          </Container>
      </header>

      <LFDialog opened={dialogData.opened} text={dialogData.text} handleClose={() => setDialogData({opened:false, text:''})}/>
    </div>
  )
}
