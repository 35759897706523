import '../../Styles/ProductDetailsPage.css';
import Banner from '../Banner';
import Footer from '../Footer';
import ProductDetailsCard from '../LeoFarataFrameWorks/ProductDetailsCard';
import {useState, useEffect, useCallback, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import LFContactSupplierDialogForm from '../LeoFarataFrameWorks/LFContactSupplierDialogForm';
import { MessageSentSnackbarContext } from '../Contexts';

export default function ProductDetailsView(){

  const location = useLocation();
  const [productDetails, setProductDetails] = useState(location.state);

  const [contactSupplierDialogFormData, setContactSupplierDialogFormData] = useState({
    opened: false,
    product: null
  });

  const [messageSentSnackbarOpened, setMessageSentSnackbarOpened] = useContext(MessageSentSnackbarContext);
  const [productQuantity, setProductQuantity] = useState(1);

  // Contacter le fournisseur ===============
  const contactSupplier = useCallback((product) => {
    (async () => {
      //console.log("Contact Fournisseur");
      setContactSupplierDialogFormData({opened:false, product:null});
      setMessageSentSnackbarOpened(messageSentSnackbarOpened => true);
    })()
  }, [contactSupplierDialogFormData]);

  return (
    <div className='page-container'>
      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur3.png" title="Fiche produit" subtitle="Sur cette page, retrouvez les details du produit que vous avez sélectionné." labelWidth="100%"/>
        <div className='page-content'>
          <div className="product-details-container">
           <ProductDetailsCard images={productDetails.images} supplier={productDetails.supplierId} title={productDetails.productName} minPrice={productDetails.minUnitPrice} alt={productDetails.productName} description={productDetails.description} composition={productDetails.composition} nutritionalBenefits={productDetails.nutritionalBenefits} use={productDetails.use} minOrderQuantity={productDetails.minOrderQuantity} onClickContactSupplier={() => setContactSupplierDialogFormData({opened:true, product:productDetails})} setProductQuantity={setProductQuantity}/>
          </div>
         </div>
         <Footer/>
      </div>

      <LFContactSupplierDialogForm opened={contactSupplierDialogFormData.opened} handleSend={() => contactSupplier(contactSupplierDialogFormData.product)} handleClose={() => setContactSupplierDialogFormData({opened:false, product:null})} productQuantity={productQuantity}/>

    </div>
  )
}
