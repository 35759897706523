//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/ProductsPage.css';
import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
//import ProductsData from '../JSON/ProductsData';
import LFModalView from '../LeoFarataFrameWorks/LFModalView';
import LFWarningAlert from '../LeoFarataFrameWorks/LFWarningAlert';
import LFContactSupplierDialogForm from '../LeoFarataFrameWorks/LFContactSupplierDialogForm';
import { ProductDeletedSnackbarContext } from '../Contexts';
import ProductCard from '../LeoFarataFrameWorks/ProductCard';
import ProductCardSkeleton from '../LeoFarataFrameWorks/ProductCardSkeleton';
import {useState, useEffect, useRef, useCallback, useContext} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../SideBar';
import TriData from '../JSON/TriData';
import {auth, db, getDocs, collection, query, where, orderBy, deleteDoc, deleteObject, ref, storage, doc} from '../Firebase/firebase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';

export default function ProductsView(){

  const location = useLocation();
  const navigateTo = useNavigate();
  const searchParamsString = location.search;
  let searchParams = new URLSearchParams(searchParamsString);

  const [sortBy, setSortBy] = useState("Prix Croissant");

  const selectedItem = useRef('');
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  const [productDeletedSnackbarOpened, setProductDeletedSnackbarOpened] = useContext(ProductDeletedSnackbarContext);

  const [warningAlertData, setWarningAlertData] = useState({
    opened: false,
    text: null,
    confirmButtonName: null,
    product: null
  });

  const [contactSupplierDialogFormData, setContactSupplierDialogFormData] = useState({
    opened: false,
    product: null
  });

  const defaultFilter = {
    price: searchParams.has("PrixMaximal") ? searchParams.get("PrixMaximal") : 3000,
    categories: searchParams.has("Categories") ? searchParams.get("Categories").split("|") : [],
    country: searchParams.has("Pays") ? searchParams.get("Pays") : "Aucun",
    productTypes: searchParams.has("TypeDeProduit") ? searchParams.get("TypeDeProduit").split("|") : [],
    manufacturingTypes: searchParams.has("TypeDeProduction") ? searchParams.get("TypeDeProduction").split("|") : [],
    orderBy: ["unitPrice", "asc"]
  };

  let oldFilter = defaultFilter;

  const [filter, setFilter] = useState({
    price: searchParams.has("PrixMaximal") ? searchParams.get("PrixMaximal") : 3000,
    categories: searchParams.has("Categories") ? searchParams.get("Categories").split("|") : [],
    country: searchParams.has("Pays") ? searchParams.get("Pays") : "Aucun",
    productTypes: searchParams.has("TypeDeProduit") ? searchParams.get("TypeDeProduit").split("|") : [],
    manufacturingTypes: searchParams.has("TypeDeProduction") ? searchParams.get("TypeDeProduction").split("|") : [],
    orderBy: ["unitPrice", "asc"]
  });

  const handleFilter = (filterName, filterValue) => {
    //console.log(filterValue);
    if(filterName === 'country') filter.country = filterValue;
    setFilter({ ...filter, [filterName]: filterValue });
    oldFilter = filter;
    //console.log(filter.country);
    if(filterName !== 'price') applyFiltersAndSearch();
  };

  const applyFiltersAndSearch = async () => {
    //console.log(filter.country);
    setLoading(loading => true);

    let jsonServerSearchURL=`https://aqso-json-server-xnrb.onrender.com/ProductsKeywords?minUnitPrice_lte=${filter.price}`;
    searchParams.set("PrixMaximal", filter.price);

    searchParams.delete("search");
    searchParams.delete("Categories");
    searchParams.delete("Pays");
    searchParams.delete("TypeDeProduit");
    searchParams.delete("TypeDeProduction");

    if(filter.categories.length !== 0){
      searchParams.set("Categories", filter.categories.join("|"));
      let normalizedFilterList = [];
      filter.categories.map((elem, index) => normalizedFilterList.push(elem.replace(/\s/g, '+')));
      jsonServerSearchURL+=`&category_like=${normalizedFilterList.join("|")}`;
    }

    if(filter.country !== "Aucun"){
      searchParams.set("Pays", filter.country/*.join("|")*/);
      //let normalizedFilterList = [];
      //filter.country.map((elem, index) => normalizedFilterList.push(elem.replace(/\s/g, '+')));
      jsonServerSearchURL+=`&country_like=${filter.country/*normalizedFilterList.join("|")*/}`;
    }

    if(filter.productTypes.length !== 0){
      searchParams.set("TypeDeProduit", filter.productTypes.join("|"));
      let normalizedFilterList = [];
      filter.productTypes.map((elem, index) => normalizedFilterList.push(elem.replace(/\s/g, '+')));
      jsonServerSearchURL+=`&productType_like=${normalizedFilterList.join("|")}`;
    }

    if(filter.manufacturingTypes.length !== 0){
      searchParams.set("TypeDeProduction", filter.manufacturingTypes.join("|"));
      let normalizedFilterList = [];
      filter.manufacturingTypes.map((elem, index) => normalizedFilterList.push(elem.replace(/\s/g, '+')));
      jsonServerSearchURL+=`&manufacturingType_like=${normalizedFilterList.join("|")}`;
    }

    //window.open(jsonServerSearchURL);

    location.search = searchParams.toString();
    navigateTo(location);

    let matchingProductNames = [];
    await axios.get(jsonServerSearchURL)
    .then(async (res) => {
      res.data.forEach((product, index) => matchingProductNames.push(product.productName));
      //console.log(matchingProductNames);
      if(matchingProductNames.length === 0) return;
      return await getDocs(query(collection(db, "products"), where("productName", "in", matchingProductNames), orderBy("createdAt", "desc")/*, orderBy(filter.orderBy[0], filter.orderBy[1])*/));
    })
    .then((docs) => {
      let productsList = [];
      if(matchingProductNames.length !== 0){
        docs.forEach(doc => productsList.push(doc.data()));
        productsList = productsList.sort((a, b) => a.minUnitPrice - b.minUnitPrice);
      }
      setProducts(products => productsList);
      setSortBy(sortBy => "Prix Croissant");
      setLoading(loading => false);
    })
    .catch((err) => console.log(err))
    //console.log(jsonServerSearchURL);
    //handleSort(sortBy);
  }

  const handleSort = (sortName) => {
    //console.log(sortBy);
    let sortedList = [...products];
    switch(sortName){
      /*case "Date":
        sortedList = sortedList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setProducts(sortedList);
        break;*/
      /*case "Type":
        sortedList = sortedList.sort((a, b) => {

        });
        setProducts(sortedList);
        break;*/
      case "Prix Croissant":
        sortedList = sortedList.sort((a, b) => a.minUnitPrice - b.minUnitPrice);
        setProducts(sortedList);
        break;
      default:
        sortedList = sortedList.sort((a, b) => b.minUnitPrice - a.minUnitPrice);
        setProducts(sortedList);
        break;
    }
  }

  // Supprimer un produit ==============================
  const deleteImage = async (productId, index) => {
    await deleteObject(ref(storage, `products/${productId}/image${index+1}.jpg`))
    .then(() => null)
    .catch((err) => null)
  }
  const deleteProduct = useCallback((product) => {
    (async () => {
      Promise.all(product.images.map((_, index) => deleteImage(product.productId, index)))
      .then(async () => {
        return await deleteDoc(doc(db, "products", product.productId))
      })
      .then(() => {
        setProducts(products => products.filter((productIterated, index) => productIterated.productId != product.productId));
        setWarningAlertData({opened:false, text:null, confirmButtonName:null, productId:null});
        setProductDeletedSnackbarOpened(productDeletedSnackbarOpened => true);
      })
      .catch((err) => console.log(err))
    })()
  }, [warningAlertData]);


  useEffect(() => {

    if(!auth?.currentUser){
      navigateTo("/ConnexionInscription", {state: { redirectURL: window.location.href }});
      return;
    }

    //searchParams.set("PrixMaximal", filter.price);
    //location.search = searchParams.toString();
    //navigateTo(location);

    (async () => {
      setLoading(loading => true);
      /*searchParams.set("PrixMaximal", filter.price);
      location.search = searchParams.toString();
      navigateTo(location);*/
      if(searchParams.has("search")){
        let matchingProductNames = [];
        await axios.get(`https://aqso-json-server-xnrb.onrender.com/ProductsKeywords?productName_like=${searchParams.get("search")}`)
          .then(async (res) => {
            res.data.forEach((product, index) => matchingProductNames.push(product.productName));
            //console.log(matchingProductNames);
            if(matchingProductNames.length === 0) return;
            return await getDocs(query(collection(db, "products"), where("productName", "in", matchingProductNames), orderBy("createdAt", "desc")/*, orderBy(filter.orderBy[0], filter.orderBy[1])*/));
          })
          .then((docs) => {
            let productsList = [];
            if(matchingProductNames.length !== 0){
              docs.forEach(doc => productsList.push(doc.data()));
              productsList = productsList.sort((a, b) => a.minUnitPrice - b.minUnitPrice);
            }
            setProducts(products => productsList);
            setLoading(loading => false);
          })
          .catch((err) => console.log(err));
        }
        else{
          await getDocs(query(collection(db, "products"), where("minUnitPrice", "<=", filter.price), orderBy("createdAt", "desc")/*, orderBy(filter.orderBy[0], filter.orderBy[1])*/))
          .then((docs) => {
            let productsList = [];
            docs.forEach(doc => productsList.push(doc.data()));
            productsList = productsList.sort((a, b) => a.minUnitPrice - b.minUnitPrice);
            setProducts(products => productsList);
            setLoading(loading => false);
          })
          .catch((err) => console.log(err));
        }
    })()
  }, []);

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur3.png" title="Produits" subtitle="Sur cette page, découvrez les produits sélectionnés par AQSO." labelWidth="100%"/>
        <div className='page-content'>
          <div className='products-page'>
            <SideBar defaultFilter={defaultFilter} oldFilter={oldFilter} filter={filter} handleFilter={handleFilter} applyFiltersAndSearch={applyFiltersAndSearch}/>
            <div className='products-page-content'>
              <div>
                <div className='products-page-content-header'>
                  <div>
                    <FormControl fullWidth placeholder="Trier par">
                      <InputLabel id="sortbylabel" sx={{'&.Mui-focused':{color:'black'}}}>Trier par</InputLabel>
                      <Select defaultValue={"Prix Croissant"} value={sortBy} sx={{backgroundColor:'white', width:'250px', height:'40px', textAlign:'left', '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="sortbylabel" id="sortby" name="sortby" label="Trier par" onChange={(e) => {setSortBy(sortBy => e.target.value); handleSort(e.target.value);}}>
                        {/*<MenuItem value={"Date"}>Date</MenuItem>/}
                        {/*<MenuItem value={"Type"}>Type</MenuItem>*/}
                        <MenuItem value={"Prix Croissant"}>Prix Croissant</MenuItem>
                        <MenuItem value={"Prix Décroissant"}>Prix Décroissant</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="products-container">
                 {loading && (new Array(8).fill(0)).map((item, index) => <ProductCardSkeleton/>)}
                 {!loading && <>
                   {products.length !== 0 && products/*.slice(0,24)*/.map((item, index) => <ProductCard key={index} index={index} productInfos={item} onClickContactSupplier={() => setContactSupplierDialogFormData({opened:true, product:item})} onClickDelete={() => setWarningAlertData({opened:true, text:'Êtes-vous sûr de vouloir supprimer ce produit ?', confirmButtonName:'Supprimer', product:item})}/>)}
                   {products.length === 0 && <div className="empty-box-container">
                     <img src="/public_assets/Extra_Icons/icone_boite_vide.png" alt="empty-box"/>
                   </div>}
                 </>}
                </div>
              </div>
            </div>
          </div>
         </div>
         <Footer/>
      </div>

      <LFWarningAlert opened={warningAlertData.opened} text={warningAlertData.text} confirmButtonName={warningAlertData.confirmButtonName} handleConfirm={() => deleteProduct(warningAlertData.product)} handleCancel={() => setWarningAlertData({opened:false, text:null, confirmButtonName:null, product:null})}/>
      <LFContactSupplierDialogForm opened={contactSupplierDialogFormData.opened} handleClose={() => setContactSupplierDialogFormData({opened:false, product:null})} targetProduct={contactSupplierDialogFormData.product}/>

    </div>
  )
}
