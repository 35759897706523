import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function LFDialog({opened, text, handleClose}) {

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        sx={{"& .MuiDialog-paper":{borderRadius:2, overflow:'hidden'}}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center">
            <InfoIcon sx={{width:30, height:30, color:'#0053CF'}}/>
            <span>Information</span>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button endIcon={<CancelRoundedIcon/>} autoFocus onClick={handleClose} sx={{color:'white', backgroundColor:'#0053CF', borderRadius:2, "&:hover":{backgroundColor:'#012d6e'}}}>
            Fermer
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
