import '../../Styles/Frameworks_Styles/ProductDetailsCard.css';
import ActivityIndicator from './ActivityIndicator';
import {useState, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import {auth} from '../Firebase/firebase';

function ProductDetailsCard({images, supplier, title, minPrice, description, composition, nutritionalBenefits, use, minOrderQuantity, alt, onClickContactSupplier, onClick, setProductQuantity}){
  const navigateTo = useNavigate();
  const [loading, setLoading] = useState(true);
  const [productNumber, setProductNumber] = useState(1);
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //sx={{width:'400px', height:'500px', backgroundColor:'blue'}}

  return(
    <Stack spacing={10} sx={{width:'800px'}}>

      <Stack direction='row' spacing={3}>
        <Stack spacing={3} onClick={onClick}>
          <Box>
            {loading === true && <ActivityIndicator/>}
            <img style={{width:'500px', height:'500px', borderRadius:'20px', objectFit:'cover', objectPosition:'center'}} src={images[selectedThumbnail]} alt={alt} onLoad={() => setLoading(loading => false)}/>
          </Box>
          <Stack direction='row' spacing={1} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            {images.map((item, index) => <Button sx={{padding:0, color:'#FF6A00', borderRadius:'15px', border:`solid ${selectedThumbnail === index ? '#FF6A00' : 'rgba(0,0,0,0)'} 3px`, boxShadow:`${selectedThumbnail === index ? '#FF6A00' : 'rgba(0,0,0,0)'} 0px 0px 20px 2.5px`, "&:hover":{border:`solid ${selectedThumbnail === index ? '#FF6A00' : '#0053CF'} 3px`, boxShadow:`${selectedThumbnail === index ? '#FF6A00' : '#0053CF'} 0px 0px 20px 2.5px`}}} onClick={() => setSelectedThumbnail(selectedThumbnail => index)}>
              <img style={{width:'70px', height:'70px', borderRadius:'12px', objectFit:'cover', objectPosition:'center'}} src={images[index]} alt={alt}/>
            </Button>)}
          </Stack>
        </Stack>

        <Stack sx={{display:'flex', justifyContent:'space-between', width:'400px', height:'500px', alignItems:'flex-start'}}>
          <Stack spacing={1} style={{width:'400px'}}>
            <Typography variant="h5" sx={{fontWeight:'bold', color:'gray'}}>{title}</Typography>
            <Typography variant="h9" sx={{fontWeight:'bold', color:'gray'}}>Quantité minimale d'achat : {minOrderQuantity}</Typography>
            <Typography variant="h6" sx={{fontWeight:'bold', color:'#FF6A00'}}>À partir de {minPrice} FCFA / {(minPrice / 655).toFixed(2)} €</Typography>
          </Stack>
          {/*<Divider sx={{width:'370px'}}/>*/}
          <Stack>
            <Typography variant="body1" sx={{color:'gray', fontWeight:'bold', mb:1}}>Quantité</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width:'230px', p:0.2, border:'solid gray 2px', borderRadius:2}}>
             <Button disabled={productNumber === 1} sx={{textTransform: 'none', backgroundColor:productNumber === 1 ? "gray" : "#FF6A00", borderRadius:1, "&:hover":{backgroundColor:"orange"}}} onClick={() => setProductNumber(productNumber => productNumber > 1 ? productNumber - 1 : productNumber - 0)}>
              <RemoveIcon sx={{color:'white', width:15, height:15}}/>
             </Button>
             <Typography variant="body1" sx={{color:'gray', fontWeight:'bold', textAlign:'center'}}>{productNumber}</Typography>
             <Button sx={{textTransform: 'none', backgroundColor:"#FF6A00", borderRadius:1, "&:hover":{backgroundColor:"orange"}}} onClick={() => setProductNumber(productNumber => productNumber >= 1 ? productNumber + 1 : productNumber + 0)}>
              <AddIcon sx={{color:'white', width:15, height:15}}/>
             </Button>
            </Stack>
          </Stack>
          {/*<Divider sx={{width:'370px'}}/>*/}
          <Stack>
            <Typography variant="body1" sx={{color:'gray', fontWeight:'bold', mb:1}}>Expédition</Typography>
            <Typography variant="body1" sx={{color:'gray', fontSize:'14px'}}>Voie maritime : 20 €/kg</Typography>
            <Typography variant="body1" sx={{color:'gray', fontSize:'14px'}}>Délai estimé : 3 semaines</Typography>
            <Typography variant="body1" sx={{color:'gray', fontSize:'14px'}}>Voie Aérienne : 40 €/kg</Typography>
            <Typography variant="body1" sx={{color:'gray', fontSize:'14px'}}>Délai estimé : 1 semaine</Typography>
          </Stack>
          {/*<Divider sx={{width:'370px'}}/>*/}
          <Stack spacing={2} sx={{width:'400px'}}>
            <Stack direction="row" justifyContent="space-between" sx={{width:'400px'}}>
              <Typography variant="body1" sx={{color:'gray', fontWeight:'bold', mb:1}}>Sous Total</Typography>
              <Typography variant="body1" sx={{color:'gray', fontWeight:'bold', mb:1}}>{minPrice * productNumber} FCFA / {((minPrice * productNumber) / 655).toFixed(2)} €</Typography>
            </Stack>
            <Button startIcon={<ChatIcon/>} sx={{textTransform: 'none', backgroundColor:"#0053CF", color:"white", fontSize:16, borderRadius:2, width:'91%', "&:hover":{backgroundColor:"#012d6e"}}} onClick={(event) => {event.stopPropagation(); setProductQuantity(productNumber); if(auth.currentUser === null){navigateTo("/ConnexionInscription")} else{onClickContactSupplier()}}}>
              Contacter le fournisseur
            </Button>
            <Typography variant="body1" sx={{color:'gray', fontSize:'14px'}}>Toujours pas décidé ? Obtenez d'abord des échantillons ! commandez un échantillon.</Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box elevation={1} sx={{ width: '100%', typography: 'body1', borderRadius:5 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{"& .MuiTabs-indicator":{backgroundColor:'#FF6A00'}}}>
              <Tab label="Description" value="1" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00'}}}/>
              <Tab label="Composition" value="2" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00'}}}/>
              <Tab label="Avantages nutritionnels" value="3" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00'}}}/>
              <Tab label="Utilisation" value="4" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00'}}}/>
            </TabList>
          </Box>
          <TabPanel value="1" sx={{fontSize:'16px', color:'gray'}}>
            <Typography variant="h7" sx={{color:'gray'}}>{description}</Typography>
          </TabPanel>
          <TabPanel value="2">
            <Typography variant="body1" sx={{color:'gray'}}>{composition}</Typography>
          </TabPanel>
          <TabPanel value="3">
            <Typography variant="body1" sx={{color:'gray'}}>{nutritionalBenefits}</Typography>
          </TabPanel>
          <TabPanel value="4">
            <Typography variant="body1" sx={{color:'gray'}}>{use}</Typography>
          </TabPanel>
        </TabContext>
      </Box>
    </Stack>
  );
}

export default memo(ProductDetailsCard);
