import '../../Styles/SuppliersPage.css';
import Banner from '../Banner';
import Footer from '../Footer';
import LFModalView from '../LeoFarataFrameWorks/LFModalView';
import SupplierCard from '../LeoFarataFrameWorks/SupplierCard';
import SupplierCardSkeleton from '../LeoFarataFrameWorks/SupplierCardSkeleton';
import {useState, useEffect, useRef} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {auth, db, getDocs, collection, query, where, orderBy} from '../Firebase/firebase';
import Grid from '@mui/material/Grid';
import SearchForm from '../LeoFarataFrameWorks/SearchForm';
import axios from 'axios';

export default function SuppliersView(){

  const navigateTo = useNavigate();
  const headerParams = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [artworkModalVisible, setSupplierModalVisible] = useState(false);
  const selectedItem = useRef('');
  const [users, setUsers] = useState([]);
  const paths = window.location.href.split("/Fournisseurs");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(!auth?.currentUser){
      navigateTo("/ConnexionInscription", {state: { redirectURL: window.location.href }});
      return;
    }

    (async () => {
      setLoading(loading => true);
      if(searchParams.has("search")){
        let matchingUsernames = [];
        await axios.get(`https://aqso-json-server-xnrb.onrender.com/SuppliersKeywords?username_like=${searchParams.get("search")}`)
          .then(async (res) => {
            res.data.forEach((user, index) => matchingUsernames.push(user.username));
            //console.log(matchingUsernames);
            if(matchingUsernames.length === 0) return;
            return await getDocs(query(collection(db, "users"), where("username", "in", matchingUsernames), orderBy("createdAt", "desc")));
          })
          .then((docs) => {
            let usersList = [];
            if(matchingUsernames.length !== 0) docs.forEach(doc => usersList.push(doc.data()));
            setUsers(users => usersList);
            setLoading(loading => false);
          })
          .catch((err) => console.log(err));
        }
        else{
          await getDocs(query(collection(db, "users"), where("accounttype", "==", "Fournisseur"), orderBy("createdAt", "desc")))
          .then((docs) => {
            let usersList = [];
            docs.forEach(doc => usersList.push(doc.data()));
            setUsers(users => usersList);
            setLoading(loading => false);
          })
          .catch((err) => console.log(err));
        }
      })()
  }, [location.search]);

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur4.png" title="Fournisseurs" subtitle="Sur cette page, découvrez les fournisseurs sélectionnés par AQSO." labelWidth="100%"/>
        <div className='page-content'>
          <div className='suppliers-page'>
            <div className='suppliers-page-header'>
              <SearchForm width={500} defaultValue={searchParams.has("search") ? searchParams.get("search") : ""} placeholder={"EX: Fournisseur1"} buttonColor="#FF6A00" iconColor="black" buttonHoverColor="orange" onSubmit={(event) => {event.preventDefault(); if(event.target.search.value.replace(/\s/g, '').length === 0){navigateTo("/Fournisseurs")}else{navigateTo("/Fournisseurs?search=" + event.target.search.value)}}} required={false}/>
            </div>
            <div className="suppliers-container">
             {loading && (new Array(8).fill(0)).map((item, index) => <SupplierCardSkeleton/>)}
             {!loading && <>
               {users.length !== 0 && users/*.slice(0,24)*/.map((item, index) => <SupplierCard key={index} index={index} supplierInfos={item}/>)}
               {users.length === 0 && <div className="empty-box-container">
                 <img src="/public_assets/Extra_Icons/icone_boite_vide.png" alt="empty-box"/>
               </div>}
             </>}
            </div>
          </div>
         </div>
         <Footer/>
      </div>

      {artworkModalVisible && <LFModalView content={<img style={{width:'100%', height:'100%', objectFit:'contain', objectPosition:'center'}} src={selectedItem.current.image} alt={selectedItem.current.alt}/>} onClose={() => setSupplierModalVisible(artworkModalVisible => false)}/>}

    </div>
  )
}
