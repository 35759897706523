//import icone_email from '../assets/icone_email.png';
//import icone_telephone from '../assets/icone_telephone.png';
//import icone_instagram from '../assets/icone_instagram.png';


export default function Banner({backgroundImage, title, subtitle, labelWidth, bannerHeight, backgroundBlur}){
  return (
    <div className='banner' style={{backgroundImage: `url(${backgroundImage})`, height:`${bannerHeight ? bannerHeight : undefined}vw`}}>
      {/*<div className="footer-contacts">
        {/*<img src={icone_email} alt="Email" onClick={() => window.open("mailto:arafatfaycal18@gmail.com")}/>
        <img src={icone_telephone} alt="Téléphone" onClick={() => window.open("tel:+33753228224")}/>
        <img src={icone_instagram} alt="Profil Instagram" onClick={() => window.open("https://www.instagram.com/leofarata/")}/>
      </div>*/}
      <div className='banner-label' style={{position:'absolute', display:'flex', flexDirection:'column', justifyContent:'center', width:labelWidth ?? null, alignItems:'center', height:'100%', backdropFilter:`blur(${backgroundBlur ? backgroundBlur : 20}px)`}}>
        <div style={{width:'100%', padding:25, boxSizing:'border-box'}}>
          {/*<h1 style={{color:'white', fontSize:30, textAlign:"center"}}>Reserve</h1>*/}
          <h1 style={{color:'white', textAlign:"center"}}>{title}</h1>
          <p style={{color:'white', textAlign:"center"}}>{subtitle}</p>
        </div>
      </div>
    </div>
  )
}
