import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import {useContext, useState} from "react";
import { PasswordChangedSnackbarContext } from '../Contexts';
import {auth, db, updateDoc, doc, updatePassword, reauthenticateWithCredential, EmailAuthProvider} from '../Firebase/firebase';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function LFChangePasswordDialogForm({opened, userInfos, /*profile, setProfile,*/ handleClose}) {

 const [loading, setLoading] = useState(false);
 const [errorLog, setErrorLog] = useState('');

 const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);

 const [passwordChangedSnackbarOpened, setPasswordChangedSnackbarOpened] = useContext(PasswordChangedSnackbarContext);

 const [formData, setFormData] = useState({
   email: userInfos.email,
   password: "",
   newPassword: ""
 })

 const changePassword = async () => {
   setLoading(loading => true);
   const credential = EmailAuthProvider.credential(formData.email, formData.password);
   await reauthenticateWithCredential(auth.currentUser, credential)
   .then(async () => {
     return await updatePassword(auth.currentUser, formData.newPassword)
   })
   .then(() => {
     setLoading(loading => false);
     setErrorLog(error => "");
     setPasswordChangedSnackbarOpened(passwordChangedSnackbarOpened => true);
     handleClose();
   })
   .catch((err) => {setLoading(loading => false); setErrorLog(errorLog => "Email ou mot de passe incorrecte.")})
 }

  return (
    </*form onSubmit={(event) => {event.preventDefault(); changePassword(event)}}*/>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        sx={{"& .MuiDialog-paper":{borderRadius:2, overflow:'hidden'}}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack direction="row" spacing={1} alignItems="center">
            <PasswordRoundedIcon sx={{width:30, height:30, color:'#0053CF'}}/>
            <span>Modification de Mot de passe</span>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack sx={{width:'100%'}} spacing={2}>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField disabled defaultValue={userInfos.email} fullWidth onChange={(event) => {setFormData({...formData, ["email"]: event.target.value})}} sx={{backgroundColor:'#f5f9ff', borderRadius:2, width:'400px', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type='email' id="email" name="email" label="Email" placeholder="Email" required/>
            </Stack>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField fullWidth onChange={(event) => {setFormData({...formData, ["password"]: event.target.value})}} sx={{backgroundColor:'#f5f9ff', borderRadius:2, width:'400px', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type={isPasswordRevealed ? "text" : "password"} id="password" name="password" label="Mot de passe Actuel" placeholder="Mot de passe Actuel" required
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordRevealed(isPasswordRevealed => !isPasswordRevealed)}
                    >
                      {isPasswordRevealed ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}/>
            </Stack>
            <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
              <TextField fullWidth onChange={(event) => {setFormData({...formData, ["newPassword"]: event.target.value})}} sx={{backgroundColor:'#f5f9ff', borderRadius:2, width:'400px', '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type={isPasswordRevealed ? "text" : "password"} id="newpassword" name="newpassword" label="Nouveau Mot de passe" placeholder="Nouveau Mot de passe" required
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsPasswordRevealed(isPasswordRevealed => !isPasswordRevealed)}
                    >
                      {isPasswordRevealed ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}/>
            </Stack>
            {errorLog !== '' && <Alert severity="error">{errorLog}</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={(formData.password.replace(/\s/g, '').length === 0 || formData.newPassword.replace(/\s/g, '').length === 0 || formData.password === formData.newPassword)}
            endIcon={<CheckCircleRoundedIcon/>}
            sx={{color:'white', backgroundColor:'#168000', borderRadius:2, boxShadow:'none', "&:hover":{backgroundColor:'#0c4500'}}}
            loadingIndicator={<CircularProgress color="inherit" size={22}/>}
            loading={loading}
            /*loadingPosition="end"*/
            variant="contained"
            type="submit"
            /*autoFocus*/
            onClick={changePassword}
          >
            Modifier
          </LoadingButton>
          <Button endIcon={<CancelRoundedIcon/>} /*autoFocus*/ onClick={handleClose} sx={{color:'white', backgroundColor:'#0053CF', borderRadius:2, "&:hover":{backgroundColor:'#012d6e'}}}>
            Annuler
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ /*form*/>
  );
}
