//import video from '../../assets/welcome_cropped.mp4';
import '../../Styles/ProfilPage.css';
import Banner from '../Banner';
//import SideBar from '../SideBar';
import Footer from '../Footer';
//import NotificationData from '../JSON/NotificationData';
import LFModalView from '../LeoFarataFrameWorks/LFModalView';
import LFWarningAlert from '../LeoFarataFrameWorks/LFWarningAlert';
import LFContactSupplierDialogForm from '../LeoFarataFrameWorks/LFContactSupplierDialogForm';
import { ProductDeletedSnackbarContext, ProfilUpdatedSnackbarContext, EmailChangedSnackbarContext, PasswordChangedSnackbarContext } from '../Contexts';
import CountriesData from '../JSON/CountriesData';
//import NotificationCard from '../LeoFarataFrameWorks/NotificationCard';
import {useState, useEffect, useRef, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Rating from '@mui/material/Rating';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _ from "lodash";
import {auth, db, doc, updateDoc, storage, uploadBytes, ref, getDownloadURL, getDocs, collection, where, query, deleteDoc, deleteObject, orderBy} from '../Firebase/firebase';
import ProductCard from '../LeoFarataFrameWorks/ProductCard';
import ProductCardSkeleton from '../LeoFarataFrameWorks/ProductCardSkeleton';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import LFChangeEmailDialogForm from '../LeoFarataFrameWorks/LFChangeEmailDialogForm';
import LFChangePasswordDialogForm from '../LeoFarataFrameWorks/LFChangePasswordDialogForm';
import axios from 'axios';

export default function ProfilView(){

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 35,
    height: 35,
    color:'#0053CF',
    border: `5px solid ${theme.palette.background.paper}`
  }));

  const navigateTo = useNavigate();

  const [changeEmailDialogFormOpened, setChangeEmailDialogFormOpened] = useState(false);
  const [changePasswordDialogFormOpened, setChangePasswordDialogFormOpened] = useState(false);

  const [emailChangedSnackbarOpened, setEmailChangedSnackbarOpened] = useContext(EmailChangedSnackbarContext);
  const [passwordChangedSnackbarOpened, setPasswordChangedSnackbarOpened] = useContext(PasswordChangedSnackbarContext);

  const [productModalVisible, setProductModalVisible] = useState(false);
  const [products, setProducts] = useState([]);

  const [loggedIn, setLoggedIn] = useState(false);

  const [value, setValue] = useState('1');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const selectedItem = useRef('');

  let userInfosString = sessionStorage.getItem("@userInfos");
  let userInfos = JSON.parse(userInfosString);
  //const [oldData, setOldData] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const [warningAlertData, setWarningAlertData] = useState({
    opened: false,
    text: null,
    confirmButtonName: null,
    product: null
  });

  const [productDeletedSnackbarOpened, setProductDeletedSnackbarOpened] = useContext(ProductDeletedSnackbarContext);
  const [profilUpdatedSnackbarOpened, setProfilUpdatedSnackbarOpened] = useContext(ProfilUpdatedSnackbarContext);

  const [profile, setProfile] = useState(/*{
    userId: userInfos.userId,
    profilPicture: userInfos.profilPicture,
    //email: userInfos.email,
    phone: userInfos.phone,
    username: userInfos.username,
    firstname: userInfos.firstname,
    name: userInfos.name,
    description: userInfos.description,
    companyId: userInfos.companyId,
    dateofbirth: userInfos.dateofbirth,
    country: userInfos.country,
    city: userInfos.city,
    address: userInfos.address,
    function: userInfos.function,
    industrialization: userInfos.industrialization,
    legalstatus: userInfos.legalstatus,
    rating: userInfos.rating,
    isVerified: userInfos.isVerified,
    accounttype: userInfos.accounttype,
    //password: userInfos.password,
    createdAt: userInfos.createdAt
  }*/userInfos);

  const handleChange = (e) => {
    let { name, value } = e.target;
    let valueCopy = value;
    valueCopy = valueCopy.replace(/\s/g, '');
    if(valueCopy.length === 0) value = null;
    setProfile({ ...profile, [name]: value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfile({ ...profile, ['profilPicture']: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  // Update Profil ===========================================================
  const updateProfilePicture = async () => {
    const media = await fetch(profile.profilPicture);
    const bytes = await media.blob();
    await uploadBytes(ref(storage, `users/${auth.currentUser.uid}/profileImage/profileImage.jpg`), bytes)
    .then(async (snapshot) => {
      return await getDownloadURL(ref(storage, snapshot.ref))
    })
    .then(async (url) => {
      setProfile({ ...profile, ['profilPicture']: url });
      userInfos.profilPicture = url;
      sessionStorage.setItem("@userInfos", JSON.stringify(profile));
      userInfosString = JSON.stringify(profile);
      userInfos = profile;
      //console.log(url);
      //console.log(_.isEqual(userInfos, profile));
    })
    .catch((err) => null);
  }

  const updateInfos = async () => {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), profile)
    .then(() => {
      sessionStorage.setItem("@userInfos", JSON.stringify(profile));
      userInfosString = JSON.stringify(profile);
      userInfos = profile;
      //console.log(profile);
      //console.log(_.isEqual(userInfos, profile));
    })
    .catch((err) => null)
  }

  const updateProfile = async () => {
    setLoading(loading => true);

    //console.log(profile);
    //console.log(userInfos);

    const userInfosCopy = JSON.parse(JSON.stringify(userInfos));
    delete userInfosCopy.profilPicture;
    const profileCopy = JSON.parse(JSON.stringify(profile));
    delete profileCopy.profilPicture;

    let promiseFunctions = [];

    if(userInfos.profilPicture != profile.profilPicture){
      promiseFunctions.push(updateProfilePicture);
      promiseFunctions.push(updateInfos);
    }

    if(!_.isEqual(userInfosCopy, profileCopy)){
      if(!promiseFunctions.includes(updateInfos)) promiseFunctions.push(updateInfos);
    }

    //console.log(userInfos.profilPicture != profile.profilPicture);


    await Promise.all(promiseFunctions.map((functionI, index) => functionI()))
    .then(async () => {
      return await axios.put(`https://aqso-json-server-xnrb.onrender.com/SuppliersKeywords/${profile.userId}`, {id: profile.userId, username: profile.username});
    })
    .then(() => {
      setLoading(loading => false);
      userInfos = profile;
      //console.log(_.isEqual(userInfos, profile));
      setProfilUpdatedSnackbarOpened(profilUpdatedSnackbarOpened => true);
      //navigateTo('/Profil');
      //window.open("/Profil", "_Self");
    })
    .catch((err) => {setLoading(loading => false); /*console.log(err)*/})
  }

  // Supprimer un produit ==============================
  const deleteImage = async (productId, index) => {
    await deleteObject(ref(storage, `products/${productId}/image${index+1}.jpg`))
    .then(() => null)
    .catch((err) => null)
  }
  const deleteProduct = useCallback((product) => {
    (async () => {
      Promise.all(product.images.map((_, index) => deleteImage(product.productId, index)))
      .then(async () => {
        return await deleteDoc(doc(db, "products", product.productId))
      })
      .then(() => {
        setProducts(products => products.filter((productIterated, index) => productIterated.productId != product.productId));
        setWarningAlertData({opened:false, text:null, confirmButtonName:null, productId:null});
        setProductDeletedSnackbarOpened(productDeletedSnackbarOpened => true);
      })
      .catch((err) => null)
    })()
  }, [warningAlertData]);


  useEffect(() => {
    //console.log(userInfos);
    //console.log(profile);
    if(profile.accounttype === "Fournisseur"){
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if(user){
          await getDocs(query(collection(db, "products"), where("supplierId", "==" , auth.currentUser.uid), orderBy("createdAt", "desc")))
          .then((docs) => {
            let productsList = [];
            docs.forEach(doc => productsList.push(doc.data()));
            setProducts(products => productsList);
            setLoadingProducts(loadingProducts => false);
          })
          .catch((err) => console.log(err))
        }
      });
      return () => unsubscribe();
    }
  }, []);

  return (
    <div className='page-container'>

      <div className='page-body'>
        <Banner backgroundImage="/public_assets/Fournisseurs/fournisseur9.png" title="Mon Profil" subtitle="Sur cette page, les informations sur votre profil." labelWidth="100%"/>
        <div className='page-content'>
          <div className="profil-container">

           {/*NotificationData/*.slice(0,24)*//*.map((item, index) => <NotificationCard key={index} index={NotificationData.length - index} image={item.image} title={item.title} description={item.description} alt={item.alt} onClick={() => {selectedItem.current = item; setArtworkModalVisible(artworkModalVisible => true)}}/>)*/}

             <Stack direction="column" spacing={3} sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', boxSizing:'border-box'}}>
               <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                 <Paper sx={{width:'100%', height:300, padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                   <Stack spacing={2} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <IconButton size="small" sx={{ color:'rgba(0,0,0,0)', "&:hover":{backgroundColor:"rgba(0,0,0,0)"}, "&.active":{backgroundColor:"rgba(0,0,0,0)"}}} onClick={(event) => {document.getElementById('profilimage').click()}} onChange={handleImageUpload}>
                       <Badge
                         invisible={!profile?.isVerified}
                         overlap="circular"
                         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                         badgeContent={
                           <SmallAvatar alt="Verified Icon" src="https://firebasestorage.googleapis.com/v0/b/aqso-e1f9b.appspot.com/o/Web%20Site%20Images%2Ficone_certifi%C3%A9.png?alt=media&token=90b28937-f852-45cd-bb12-d427ad354f2a" />
                         }
                       >
                         <Avatar sx={{width:120, height:120, border: `8px solid black`}} alt="Profil Image" src={profile.profilPicture}/>
                       </Badge>
                       <input type="file" id='profilimage' style={{ display:'none' }}/>
                       <Box sx={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', borderRadius:150, opacity:'0%', '&:hover':{backgroundColor:'rgba(0,0,0,0.3)', opacity:'100%'}}}>
                        <AddCircleIcon sx={{width:20, height:20}}/>
                       </Box>
                     </IconButton>
                     <Typography variant="h5" sx={{textAlign:'center'}}>{profile.username}</Typography>
                     <Typography variant="body1" color="text.secondary" sx={{textAlign:'center'}}>{profile.accounttype}</Typography>
                     <Rating name="rating" value={profile.rating} readOnly/>
                   </Stack>
                 </Paper>
               </div>

               <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                 <Paper sx={{width:'100%', padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                   <Box elevation={1} sx={{ width: '100%', typography: 'body1', borderRadius:5 }}>
                     <TabContext value={value}>
                       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                         <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{"& .MuiTabs-indicator":{backgroundColor:'#FF6A00'}}} variant="fullWidth">
                           {/*<Tab label="Description" value="1" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00'}}}/>*/}
                           {profile.accounttype === "Fournisseur" && <Tab label="Produits" value="1" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00', background:'#fcf7f2', borderRadius:'10px'}}}/>}
                           <Tab label="Profil" value="2" sx={{textTransform:'none', fontSize:'18px', "&.Mui-selected":{color:'#FF6A00', background:'#fcf7f2', borderRadius:'10px'}}}/>
                         </TabList>
                       </Box>
                     </TabContext>
                   </Box>
                 </Paper>
               </div>


               <TabContext value={value}>
                 {profile.accounttype === "Fournisseur" && <TabPanel value="1" sx={{p:0, width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                   {/*<Paper sx={{width:'100%', height:600, padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center', boxSizing:'border-box', overflow:'hidden'}} elevation={2}>
                   </Paper>*/}
                   <Stack spacing={5}>
                     <div className="products-container">
                      {loadingProducts && (new Array(8).fill(0)).map((item, index) => <ProductCardSkeleton/>)}
                      {!loadingProducts && <>
                        {products.length !== 0 && products/*.slice(0,24)*/.map((item, index) => <ProductCard key={index} index={index} productInfos={item} onClickDelete={() => setWarningAlertData({opened:true, text:'Êtes-vous sûr de vouloir supprimer ce produit ?', confirmButtonName:'Supprimer', product:item})}/>)}
                        {products.length === 0 && <div className="empty-box-container">
                          <img src="/public_assets/Extra_Icons/icone_boite_vide.png" alt="empty-box"/>
                        </div>}
                      </>}
                     </div>
                     <Button startIcon={<PlaylistAddCircleIcon/>} sx={{display:'flex', alignSelf:'flex-end', textTransform: 'none', backgroundColor:"#FF6A00", color:"white", fontSize:16, borderRadius:2, width:250, "&:hover":{backgroundColor:"orange"}}} onClick={(event) => {event.stopPropagation(); navigateTo("/AjouterUnProduit")}}>
                       Ajouter un produit
                     </Button>
                   </Stack>
                 </TabPanel>}

                 <form style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', margin:profile.accounttype === "Fournisseur" ? 0 : undefined}} action='/Profil' onSubmit={(event) => {event.preventDefault(); updateProfile()}}>
                   <TabPanel value={profile.accounttype === "Fournisseur" ? "2" : "1"} sx={{p:0, width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <Paper sx={{width:'100%', padding:4, borderRadius:5, display:'flex', justifyContent:'center', alignItems:'center'}} elevation={2}>
                       <Stack sx={{width:'100%'}} spacing={3}>
                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                           <TextField defaultValue={profile.firstname} onChange={handleChange} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="firstname" name="firstname" label="Prénom" placeholder="Prénom" required/>
                           <TextField defaultValue={profile.name} onChange={handleChange} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="name" name="name" label="Nom" placeholder="Nom" required/>
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                             <DatePicker format="DD/MM/YYYY" onChange={(value) => {setProfile({ ...profile, ['dateofbirth']: (new Date(value.toDate())).toDateString()})}} defaultValue={dayjs(profile.dateofbirth)} sx={{backgroundColor:'#f5f9ff', width:'100%', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} slotProps={{textField: {error:false, required: false, id:"dateofbirth", name:"dateofbirth"}}} label="Date de naissance" /*required*//>
                           </LocalizationProvider>
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                           <TextField disabled value={profile.email} /*onChange={handleChange}*/ fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="email" name="email" label="Email" placeholder="Email" required/>
                           {/*<Button endIcon={<AlternateEmailRoundedIcon/>} onClick={() => setChangeEmailDialogFormOpened(changeEmailDialogFormOpened => true)} sx={{px:5, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>Modifier</Button>*/}
                           <TextField disabled value={profile.password ?? "********"} /*onChange={handleChange}*/ fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="password" id="password" name="password" label="Mot de passe" placeholder="*********" required/>
                           <Button endIcon={<PasswordRoundedIcon/>} onClick={() => setChangePasswordDialogFormOpened(changePasswordDialogFormOpened => true)} sx={{px:5, backgroundColor:'#012d6e', borderRadius:'5px', color:'white', "&:hover":{backgroundColor:'#001430'}}}>Modifier</Button>
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                           <TextField defaultValue={profile.username} onChange={handleChange} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="username" name="username" label="Nom d'utilisateur / Entreprise" placeholder="Nom d'utilisateur / Entreprise" required/>
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                           {/*<FormControl fullWidth placeholder="Type de compte" required>
                             <InputLabel id="accounttypelabel" sx={{'&.Mui-focused':{color:'black'}}}>Type de compte</InputLabel>
                             <Select fullWidth defaultValue={profile.accounttype} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="accounttypelabel" id="accounttype" name="accounttype" label="Type de compte">
                               <MenuItem value={"Acheteur"}>Acheteur</MenuItem>
                               <MenuItem value={"Fournisseur"}>Fournisseur</MenuItem>
                             </Select>
                           </FormControl>*/}

                           {profile.accounttype === "Fournisseur" && <TextField defaultValue={profile.function} onChange={handleChange} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="function" name="function" label="Fonction" placeholder="Fonction" /*required*//>}
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                           <FormControl fullWidth placeholder="Pays" required>
                             <InputLabel id="countrylabel" sx={{'&.Mui-focused':{color:'black'}}}>Pays</InputLabel>
                             <Select fullWidth defaultValue={profile.country ?? ''} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="countrylabel" id="country" name="country" label="Pays">
                               {CountriesData.map((item, index) => <MenuItem key={index} value={item.name}>{item.name}</MenuItem>)}
                             </Select>
                           </FormControl>

                           <FormControl fullWidth placeholder="Ville" /*required*/>
                             <InputLabel id="citylabel" sx={{'&.Mui-focused':{color:'black'}}}>Ville</InputLabel>
                             <Select fullWidth defaultValue={profile.city ?? ''} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="citylabel" id="city" name="city" label="Ville">
                               {CountriesData.map((item, index) => <MenuItem key={index} value={item.name}>{item.name}</MenuItem>)}
                             </Select>
                           </FormControl>
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                          <TextField defaultValue={profile.address} onChange={handleChange} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="address" name="address" label="Addresse" placeholder="Addresse" /*required*//>
                         </Stack>

                         <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                          <TextField defaultValue={profile.phone} onChange={handleChange} fullWidth sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} type="tel" id="phone" name="phone" label="Téléphone" placeholder="Téléphone" /*required*/ InputProps={{startAdornment:<InputAdornment position="start" sx={{pl:1}}>{(CountriesData.find(countryIterated => countryIterated.name == profile.country)).dial_code}</InputAdornment>}}/>
                         </Stack>

                         {profile.accounttype === "Fournisseur" &&
                          <>
                           <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                             <FormControl fullWidth placeholder="Industrialisation" /*required*/>
                               <InputLabel id="industrializationlabel" sx={{'&.Mui-focused':{color:'black'}}}>Industrialisation</InputLabel>
                               <Select fullWidth defaultValue={profile.industrialization ?? ''} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="industrializationlabel" id="industrialization" name="industrialization" label="Industrialisation">
                                 <MenuItem value={"Semi-Industrialisation"}>Semi-Industrialisation</MenuItem>
                               </Select>
                             </FormControl>
                             <FormControl fullWidth placeholder="Statut juridique" /*required*/>
                               <InputLabel id="legalstatuslabel" sx={{'&.Mui-focused':{color:'black'}}}>Statut juridique</InputLabel>
                               <Select fullWidth defaultValue={profile.legalstatus ?? ''} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '&.MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} labelId="legalstatuslabel" id="legalstatus" name="legalstatus" label="Statut juridique">
                                 <MenuItem value={"SARL"}>SARL</MenuItem>
                                 <MenuItem value={"SASU"}>SASU</MenuItem>
                               </Select>
                           </FormControl>
                           </Stack>

                           <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                            <TextField fullWidth defaultValue={profile.companyId} onChange={handleChange} sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="companyId" name="companyId" label="Identifiant de l'entreprise" placeholder="Identifiant de l'entreprise" required/>
                           </Stack>

                           <Stack sx={{width:'100%'}} direction="row" spacing={1.5}>
                            <TextField fullWidth defaultValue={profile.description} onChange={handleChange} multiline rows={4} /*maxRows={4}*/ sx={{backgroundColor:'#f5f9ff', borderRadius:2, '& label.Mui-focused':{color:'black'}, '& .MuiOutlinedInput-root':{borderRadius:2, '& fieldset':{borderColor: '#E0E3E7'},'&:hover fieldset':{borderColor:'black'},'&.Mui-focused fieldset':{borderColor:'black', borderWidth:2.5}}}} error={false} id="description" name="description" label="Description de l'entreprise" placeholder="Description de l'entreprise" /*required*//>
                           </Stack>
                          </>
                         }

                         <Stack sx={{display:'flex', width:'100%', justifyContent:'flex-end'}} direction="row" spacing={1.5}>
                           <LoadingButton
                             disabled={_.isEqual(userInfos, profile)}
                             sx={{color:"white", textTransform: 'none', fontWeight:'bold', width:150, borderRadius:2}}
                             loadingIndicator={<CircularProgress color="inherit" size={32}/>}
                             loading={loading}
                             /*loadingPosition="end"*/
                             variant="contained"
                             type="submit"
                             //onClick={() => updateProfile()}
                           >
                             <span style={{marginTop:3.5}}>Enregistrer</span>
                           </LoadingButton>
                         </Stack>
                       </Stack>
                     </Paper>
                   </TabPanel>
                 </form>
               </TabContext>

             </Stack>

          </div>
         </div>
         <Footer/>
      </div>

      <LFWarningAlert opened={warningAlertData.opened} text={warningAlertData.text} confirmButtonName={warningAlertData.confirmButtonName} handleConfirm={() => deleteProduct(warningAlertData.product)} handleCancel={() => setWarningAlertData({opened:false, text:null, confirmButtonName:null, product:null})}/>
      <LFChangeEmailDialogForm opened={changeEmailDialogFormOpened} userInfos={userInfos} profile={profile} setProfile={setProfile} handleClose={() => setChangeEmailDialogFormOpened(changeEmailDialogFormOpened => false)}/>
      <LFChangePasswordDialogForm opened={changePasswordDialogFormOpened} userInfos={userInfos} /*profile={profile} setProfile={setProfile}*/ handleClose={() => {setChangePasswordDialogFormOpened(changePasswordDialogFormOpened => false)}}/>

    </div>
  )
}
